import requests from '../../requests'

export default {
  categories: {
    list () {
      return requests.get('meta/certifications/categories')
    },
    add (o) {
      return requests.post('meta/certifications/categories', o)
    },
    update (o) {
      return requests.put(`meta/certifications/categories/${o.id}`, o)
    },
    delete (o) {
      return requests.delete(`meta/certifications/categories/${o.id}`)
    },
    types: {
      add (category, o) {
        return requests.post(`meta/certifications/categories/${category.id}/types`, o)
      },
      update (category, o) {
        return requests.put(`meta/certifications/categories/${category.id}/types/${o.id}`, o)
      },
      delete (category, o) {
        return requests.delete(`meta/certifications/categories/${category.id}/types/${o.id}`)
      }
    }
  }
}
