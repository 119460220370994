import requests from '../../../requests'

export default {
  list () {
    return requests.get('logistics/transport/jobs')
  },
  get (id) {
    return requests.get(`logistics/transport/jobs/${id}`)
  },
  create (o) {
    return requests.post('logistics/transport/jobs', o)
  },
  update (o) {
    return requests.put(`logistics/transport/jobs/${o.id}`, o)
  },
  delete (o) {
    return requests.delete(`logistics/transport/jobs/${o.id}`)
  }
}
