import requests from '../../requests'

export default {
  list (asset) {
    return requests.get(`assets/${asset.id}/stock`)
  },
  sync (asset) {
    return requests.post(`assets/${asset.id}/stock/sync`)
  },
  increase (asset, pool, o) {
    return requests.post(`assets/${asset.id}/stock/${pool.id}/increase`, o)
  },
  decrease (asset, pool, o) {
    return requests.post(`assets/${asset.id}/stock/${pool.id}/decrease`, o)
  },
  transfer (asset, pool, o) {
    return requests.post(`assets/${asset.id}/stock/${pool.id}/transfer`, o)
  },
  deleteTransaction (asset, pool, txn) {
    return requests.delete(`assets/${asset.id}/stock/${pool.id}/transaction/${txn.id}`)
  },
  fix_cache (asset, pool) {
    return requests.post(`assets/${asset.id}/stock/${pool.id}/fix`)
  },
  transactions: {
    fix (asset, pool) {
      return requests.post(`assets/${asset.id}/stock/${pool.id}/transaction/fix`)
    },
    remove (asset, pool, txn) {
      return requests.delete(`assets/${asset.id}/stock/${pool.id}/transaction/${txn.id}`)
    }
  }
}
