import requests from '../../../requests'

export default {
  submit (b) {
    return requests.post(`logistics/crewing/bookings/${b.id}/submit`)
  },
  cancel (b) {
    return requests.post(`logistics/crewing/bookings/${b.id}/cancel`)
  },
  mark_as (b, t) {
    return requests.post(`logistics/crewing/bookings/${b.id}/mark-as/${t}`)
  },
  get_history (b) {
    return requests.get(`logistics/crewing/bookings/${b.id}/history`)
  }
}
