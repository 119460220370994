export default [
  {
    path: '/system',
    component: () => import('../../views/app/system/index.vue'),
    redirect: { name: 'AssetMigration' },
    children: [
      {
        path: 'asset-migration',
        name: 'AssetMigration',
        component: () => import('../../views/app/system/AssetMigration.vue')
      },
      {
        path: 'company-migration',
        component: () => import('../../views/app/system/CompanyMigration.vue')
      },
      {
        path: 'log',
        component: () => import('../../views/app/system/Log.vue')
      }
    ]
  }
]
