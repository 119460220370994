import requests from '../../requests'

export default {
  list() {
    return requests.get('finance/nominal-accounts')
  },
  recompute_balances() {
    return requests.post('finance/nominal-accounts/recompute-balances')
  },
  get(id) {
    return requests.get(`finance/nominal-accounts/${id}`)
  },
  create(o) {
    return requests.post('finance/nominal-accounts', o)
  },
  update(o) {
    return requests.put(`finance/nominal-accounts/${o.id}`, o)
  },
  delete(o) {
    return requests.delete(`finance/nominal-accounts/${o.id}`)
  },
  init_special() {
    return requests.post('finance/nominal-accounts/init-special')
  },
  sync_xero() {
    return requests.post('finance/nominal-accounts/sync-xero')
  },
  transactions: {
    list(o) {
      return requests.get(`finance/nominal-accounts/${o.id}/transactions`)
    }
  },
  journals: {
    get(id) {
      return requests.get(`finance/nominal-accounts/journals/${id}`)
    }
  }
}
