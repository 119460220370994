import requests from '../requests'

export default {
  list () {
    return requests.get('meta/finance/pricing-policies')
  },
  get (id) {
    return requests.get(`meta/finance/pricing-policies/${id}`)
  },
  create (obj) {
    return requests.post('meta/finance/pricing-policies', obj)
  },
  update (obj) {
    return requests.put(`meta/finance/pricing-policies/${obj.id}`, obj)
  },
  delete (obj) {
    return requests.delete(`meta/finance/pricing-policies/${obj.id}`)
  }
}
