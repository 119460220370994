import requests from '../requests'

export default {
  list () {
    return requests.get('freelancers')
  },
  get (id) {
    return requests.get(`freelancers/${id}`)
  },
  update (fl) {
    return requests.put(`freelancers/${fl.id}`, fl)
  },
  delete (fl) {
    return requests.delete(`freelancers/${fl.id}`)
  },
  skills: {
    list (fl) {
      return requests.get(`freelancers/${fl.id}/skills`)
    },
    add (fl, skill) {
      return requests.post(`freelancers/${fl.id}/skills`, skill)
    },
    remove (fl, skill) {
      return requests.delete(`freelancers/${fl.id}/skills/${skill.id}`)
    }
  }
}
