import requests from '../../requests'

export default {
  xero: {
    status() {
      return requests.get('system/integration/xero')
    },
    authorise() {
      return requests.post('system/integration/xero/authorise')
    },
    establish(o) {
      return requests.post('system/integration/xero/establish', o)
    },
    tenants() {
      return requests.get('system/integration/xero/tenants')
    },
    set_tenant(t) {
      return requests.post('system/integration/xero/tenants', { id: t })
    }
  },
  teamtrack: {
    map: {
      people: {
        list() {
          return requests.get('system/integration/teamtrack/map/people')
        },
        link(o) {
          return requests.post(`system/integration/teamtrack/map/people/${o.arc}/link`, o)
        },
        unlink(o) {
          return requests.delete(`system/integration/teamtrack/map/people/${o.arc}/link`)
        }
      }
    }
  }
}
