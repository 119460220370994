export default [
  {
    path: 'transport',
    component: () => import('../../../views/app/logistics/transport/index.vue'),
    redirect: { name: 'TransportSchedules' },
    children: [
      {
        path: 'schedules',
        name: 'TransportSchedules',
        component: () => import('../../../views/app/logistics/transport/schedules/index.vue')
      },
      {
        path: 'fleet',
        component: () => import('../../../views/app/logistics/transport/fleet/index.vue')
      }
    ]
  }
]
