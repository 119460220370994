import auth from './auth';
import projects from './projects';
import meta from './meta';
import venues from './venues';
import finance from './finance';
import companies from './companies';
import people from './people';
import warehouse from './warehouse';
import warehouse2 from './warehouse2';
import items from './items';
import hires from './hires';
import compliance from './compliance';
import logistics from './logistics';
import system from './system';
import dashboards from './dashboards';
import freelancers from './freelancers';
import search from './search';
import todo from './todo';
import enquiries from './enquiries';
import workshop from './workshop';
import contracts from './contracts';
import requests from './requests';

export default {
  info() {
    return requests.get('info');
  },
  auth,
  projects,
  contracts,
  meta,
  venues,
  finance,
  companies,
  people,
  warehouse,
  warehouse2,
  items,
  hires,
  compliance,
  logistics,
  system,
  dashboards,
  freelancers,
  search,
  todo,
  enquiries,
  workshop,
  people_groups: {
    list() {
      return requests.get('people-groups');
    },
    create(o) {
      return requests.post('people-groups', o);
    },
    update(o) {
      return requests.put(`people-groups/${o.id}`, o);
    },
    delete(o) {
      return requests.delete(`people-groups/${o.id}`);
    },
    members: {
      add(group, member) {
        return requests.post(`people-groups/${group.id}/${member.id}`);
      },
      remove(group, member) {
        return requests.delete(`people-groups/${group.id}/${member.id}`);
      },
    }
  },
  quotable_elements: {
    list(f) {
      return requests.get('quotable-elements');
    },
    list_for_group(groupId) {
      return requests.get(`quotable-elements?withPrices=false&forGroup=${groupId}`);
    },
    create(o) {
      return requests.post('quotable-elements', o);
    },
    update(o) {
      return requests.put(`quotable-elements/${o.id}`, o);
    },
    delete(o) {
      return requests.delete(`quotable-elements/${o.id}`);
    }
  },
  ng_reports: {
    quote(q, redact = false) {
      requests.download(`ng-reports/quote/${q.id}?redact=${redact}`);
    },
    preview_quote(q, redact = false) {
      return requests.open(`ng-reports/quote/${q.id}?preview=true&redact=${redact}`);
    },
    contract(c) {
      return requests.download(`ng-reports/contract/${c.id}`);
    },
    preview_contract(c) {
      return requests.open(`ng-reports/contract/${c.id}?preview=true`);
    },
    paydoc(p) {
      requests.download(`ng-reports/paydoc/${p.id}`);
    },
    preview_paydoc(p) {
      return requests.open(`ng-reports/paydoc/${p.id}?preview=true`);
    },
    preview_pick_status(g) {
      return requests.open(`ng-reports/warehouse/pick-status/${g.id}?preview=true`);
    },
    preview_weight_report(p) {
      return requests.open(`ng-reports/projects/weight/${p.id}?preview=true`);
    },
    preview_problem_report(condensed) {
      return requests.open(`ng-reports/warehouse/asset-problems?preview=true&condensed=${condensed}`);
    },
    finance: {
      preview_expired_po() {
        return requests.open(`ng-reports/finance/expired-po?preview=true`);
      },
      preview_overdue_si() {
        return requests.open(`ng-reports/finance/overdue-si?preview=true`);
      },
      historical_sales() {
        return requests.download(`ng-reports/finance/historical-sales`);
      },
    },
    warehouse: {
      historical_asset_usage(a) {
        return requests.download(`ng-reports/warehouse/asset-usage/${a.id}`);
      },
    }
  },
  legacy_schedule: {
    events: {
      list(from, to) {
        return requests.get(`legacy-schedule/events?from=${from}&to=${to}`);
      }
    }
  },
  lookup(search) {
    return requests.post('lookup', search);
  },
  display_screens: {
    list() {
      return requests.get('display-screens');
    },
    create(o) {
      return requests.post('display-screens', o);
    },
    update(o) {
      return requests.put(`display-screens/${o.id}`, o);
    },
    delete(o) {
      return requests.delete(`display-screens/${o.id}`);
    },
    actions: {
      blank(o) {
        return requests.post(`display-screens/${o.id}/blank`);
      },
      unblank(o) {
        return requests.delete(`display-screens/${o.id}/blank`);
      }
    }
  }
};
