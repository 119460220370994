import requests from '../requests'

export default {
  list () {
    return requests.get('items')
  },
  get (id) {
    return requests.get(`items/${id}`)
  },
  create (obj) {
    return requests.post('items', obj)
  },
  update (obj) {
    return requests.put(`items/${obj.id}`, obj)
  },
  delete (obj) {
    return requests.delete(`items/${obj.id}`)
  },
  provider_groups: {
    list (item) {
      return requests.get(`items/${item.id}/provider-groups`)
    },
    get (item, id) {
      return requests.get(`items/${item.id}/provider-groups/${id}`)
    },
    create (item, grp) {
      return requests.post(`items/${item.id}/provider-groups`, grp)
    },
    update (item, grp) {
      return requests.put(`items/${item.id}/provider-groups/${grp.id}`, grp)
    },
    delete (item, grp) {
      return requests.delete(`items/${item.id}/provider-groups/${grp.id}`)
    },
    move_up (item, grp) {
      return requests.post(`items/${item.id}/provider-groups/${grp.id}/move-up`)
    },
    move_down (item, grp) {
      return requests.post(`items/${item.id}/provider-groups/${grp.id}/move-down`)
    },
    providers: {
      list (item, grp) {
        return requests.get(`items/${item.id}/provider-groups/${grp.id}/providers`)
      },
      get (item, grp, id) {
        return requests.get(`items/${item.id}/provider-groups/${grp.id}/providers/${id}`)
      },
      create (item, grp, pv) {
        return requests.post(`items/${item.id}/provider-groups/${grp.id}/providers`, pv)
      },
      update (item, grp, pv) {
        return requests.put(`items/${item.id}/provider-groups/${grp.id}/providers/${pv.id}`, pv)
      },
      delete (item, grp, pv) {
        return requests.delete(`items/${item.id}/provider-groups/${grp.id}/providers/${pv.id}`)
      },
      move_up (item, grp, pv) {
        return requests.post(`items/${item.id}/provider-groups/${grp.id}/providers/${pv.id}/move-up`)
      },
      move_down (item, grp, pv) {
        return requests.post(`items/${item.id}/provider-groups/${grp.id}/providers/${pv.id}/move-down`)
      },
      assets: {
        list (item, grp, pv) {
          return requests.get(`items/${item.id}/provider-groups/${grp.id}/providers/${pv.id}/assets`)
        },
        get (item, grp, pv, asset) {
          return requests.get(`items/${item.id}/provider-groups/${grp.id}/providers/${pv.id}/assets/${asset.id}`)
        },
        create (item, grp, pv, assetId, obj) {
          return requests.post(`items/${item.id}/provider-groups/${grp.id}/providers/${pv.id}/assets/${assetId}`, obj)
        },
        update (item, grp, pv, asset) {
          return requests.put(`items/${item.id}/provider-groups/${grp.id}/providers/${pv.id}/assets/${asset.id}`, asset)
        },
        delete (item, grp, pv, asset) {
          return requests.delete(`items/${item.id}/provider-groups/${grp.id}/providers/${pv.id}/assets/${asset.id}`)
        }
      }
    }
  },
  suppliers: {
    list (item) {
      return requests.get(`items/${item.id}/suppliers`)
    },
    add (item, supplier) {
      return requests.post(`items/${item.id}/suppliers/${supplier.id}`)
    },
    remove (item, supplier) {
      return requests.delete(`items/${item.id}/suppliers/${supplier.id}`)
    }
  },
  prices: {
    list (item) {
      return requests.get(`items/${item.id}/prices`)
    },
    policies: {
      list (item, policy) {
        return requests.get(`items/${item.id}/prices/${policy.id}`)
      },
      prices: {
        get (item, policy, period) {
          return requests.get(`items/${item.id}/prices/${policy.id}/${period}`)
        },
        set (item, policy, period, amount) {
          return requests.put(`items/${item.id}/prices/${policy.id}/${period}`, { amount })
        },
        unset (item, policy, period) {
          return requests.delete(`items/${item.id}/prices/${policy.id}/${period}`)
        }
      }
    }
  },
  config: {
    getTree (item) {
      return requests.get(`items/${item.id}/config`)
    },
    getFlat (item) {
      return requests.get(`items/${item.id}/config/flat`)
    },
    getLegacy (item) {
      return requests.get(`items/${item.id}/config/legacy`)
    },
    addElement (item, obj) {
      return requests.post(`items/${item.id}/config`, obj)
    },
    migrate (item) {
      return requests.post(`items/${item.id}/config/migrate`)
    },
    elements: {
      update (item, element) {
        return requests.put(`items/${item.id}/config/${element.id}`, element)
      },
      delete (item, element) {
        return requests.delete(`items/${item.id}/config/${element.id}`)
      },
      reparent (item, element, obj) {
        return requests.put(`items/${item.id}/config/${element.id}/parent`, obj)
      },
      moveUp (item, element) {
        return requests.post(`items/${item.id}/config/${element.id}/move-up`)
      },
      moveDown (item, element) {
        return requests.post(`items/${item.id}/config/${element.id}/move-down`)
      }
    }
  }
}
