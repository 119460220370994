import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import './plugins/fontawesome';
import App from './App.vue';
import router from './router/index.js';
import store from './store/index.js';
import './plugins/selectors.js';
import apolloProvider from './plugins/apollo.js';
import 'vue-select/dist/vue-select.css'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app');
