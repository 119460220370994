import requests from '../../requests'

export default {
  list (project) {
    return requests.get(`projects/${project.id}/hires/groups`)
  },
  get (project, gid) {
    return requests.get(`projects/${project.id}/hires/groups/${gid}`)
  },
  create (project, obj) {
    return requests.post(`projects/${project.id}/hires/groups`, obj)
  },
  update (project, obj) {
    return requests.put(`projects/${project.id}/hires/groups/${obj.id}`, obj)
  },
  delete (project, obj) {
    return requests.delete(`projects/${project.id}/hires/groups/${obj.id}`)
  },
  create_quote (project, grp, obj) {
    return requests.post(`projects/${project.id}/hires/groups/${grp.id}/quote`, obj)
  }
}
