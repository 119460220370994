<template>
  <vue-date-range-picker
    single-date-picker
    auto-apply
    :locale-data="{ firstDay: 1, format: 'dddd dd-mm-yyyy' }"
    :disabled="disabled"
    :date-range="dateValue"
    @update="dateValueChange" />
</template>

<script>
import VueDateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Moment from 'moment'

export default {
  name: 'date-selector',
  props: ['value', 'disabled'],
  components: { VueDateRangePicker },
  computed: {
    dateValue () {
      return {
        startDate: this.value
      }
    }
  },
  methods: {
    dateValueChange (v) {
      this.$emit('input', Moment(v.startDate).format())
    }
  }
}
</script>
