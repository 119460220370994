import requests from '../../requests'

export default {
  list() {
    return requests.get('finance/charge-accounts')
  },
  recompute_balances() {
    return requests.post('finance/charge-accounts/recompute-balances')
  },
  get(id) {
    return requests.get(`finance/charge-accounts/${id}`)
  },
  create(o) {
    return requests.post('finance/charge-accounts', o)
  },
  update(o) {
    return requests.put(`finance/charge-accounts/${o.id}`, o)
  },
  delete(o) {
    return requests.delete(`finance/charge-accounts/${o.id}`)
  },
  sync_project_accounts() {
    return requests.post('finance/charge-accounts/sync-project-accounts')
  },
  transactions: {
    list(o) {
      return requests.get(`finance/charge-accounts/${o.id}/transactions`)
    },
    approve(o, t) {
      return requests.post(`finance/charge-accounts/${o.id}/transactions/${t.id}/approve`)
    },
    unapprove(o, t) {
      return requests.delete(`finance/charge-accounts/${o.id}/transactions/${t.id}/approve`)
    }
  },
  internal_transfers: {
    create(o) {
      return requests.post(`finance/charge-accounts/internal-transfers`, o)
    }
  }
}
