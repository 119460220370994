import requests from '../requests'

export default {
  adjustment_reasons: {
    list (type) {
      return requests.get(`meta/stock-adjustment-reasons/${type}`)
    },
    add (type, o) {
      return requests.post(`meta/stock-adjustment-reasons/${type}`, o)
    },
    update (type, o) {
      return requests.put(`meta/stock-adjustment-reasons/${type}/${o.id}`, o)
    },
    delete (type, o) {
      return requests.delete(`meta/stock-adjustment-reasons/${type}/${o.id}`)
    }
  },
  list () {
    return requests.get('meta/stock-pools')
  },
  get (id) {
    return requests.get(`meta/stock-pools/${id}`)
  },
  create (o) {
    return requests.post('meta/stock-pools', o)
  },
  update (o) {
    return requests.put(`meta/stock-pools/${o.id}`, o)
  },
  delete (o) {
    return requests.delete(`meta/stock-pools/${o.id}`)
  }
}
