import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import people from './modules/people'
import meta from './modules/meta'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    meta,
    people
  }
})
