const STATE_INVALID = 0
const STATE_LOADING = 1
const STATE_VALID = 2

const state = () => ({
  categories: [],
  sites: [],
  departments: [],
  validity: STATE_INVALID
})

const getters = {
  categories: (s) => s.categories,
  sites: (s) => s.sites,
  departments: (s) => s.departments,
  invalid: (s) => s.validity === STATE_INVALID
}

const actions = {
  async load ({ commit, getters }) {
    if (getters.invalid) {
      commit('updateValidity', STATE_LOADING)
      const [categories, sites, departments] = await Promise.all([
        ArcAPI.meta.categories.list(),
        ArcAPI.meta.company.sites.list(),
        ArcAPI.meta.company.departments.list()
      ])

      commit('setCategories', categories)
      commit('setSites', sites)
      commit('setDepartments', departments)
      commit('updateValidity', STATE_VALID)
    }
  },
  async invalidate ({ commit }) {
    commit('invalidate')
  }
}

const mutations = {
  updateValidity (s, v) {
    s.validity = v
  },
  setCategories (s, v) {
    s.categories = v
  },
  setSites (s, v) {
    s.sites = v
  },
  setDepartments (s, v) {
    s.departments = v
  },
  invalidate (s) {
    s.categories = []
    s.sites = []
    s.departments = []
    s.validity = STATE_INVALID
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
