import requests from '../requests.js';

export default {
  jobs: {
    list() {
      return requests.get('workshop/jobs');
    }
  },
  hires: {
    list() {
      return requests.get('workshop/hires');
    }
  }
};
