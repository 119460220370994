const STATE_INVALID = 0
const STATE_LOADING = 1
const STATE_VALID = 2

const state = () => ({
  list: [],
  validity: STATE_INVALID
})

const getters = {
  list: (s) => s.list,
  isValid: (s) => s.validity === STATE_VALID,
  loading: (s) => s.validity === STATE_LOADING
}

const actions = {
  async load ({ commit, state }) {
    if (state.validity === STATE_INVALID) {
      commit('updateValidity', STATE_LOADING)
      commit('setList', await ArcAPI.people.list_short())
      commit('updateValidity', STATE_VALID)
    }
  },
  addPerson ({ commit, state }, value) {
  },
  async invalidate ({ commit }) {
    commit('invalidate')
  }
}

const mutations = {
  updateValidity (s, v) {
    s.validity = v
  },
  setList (s, v) {
    s.list = v
  },
  invalidate (s) {
    s.list = []
    s.validity = STATE_INVALID
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
