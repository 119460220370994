<template>
  <searchable-object-selector
      ref="selector"
      :objects="sortedItems"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="input"
      :value="value"
      :size="size" />
</template>

<script>
export default {
  name: 'item-selector',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default () {
        return false
      }
    },
    disabled: {
      Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String
  },
  data () {
    return {
      items: []
    }
  },
  computed: {
    sortedItems () {
      return this.items.slice().sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    selectById (id) {
      this.$refs.selector.selectById(id)
    }
  },
  async mounted () {
    this.items = await ArcAPI.items.list()
  }
}
</script>
