const TOKEN_LOCAL_STORAGE_NAME = 'auth-token'

const state = () => ({
  user: null,
  authToken: null,
  scannerAuthToken: null,
  scannerOperator: null,
  progress: 'idle',
  info: null
})

const getters = {
  user: (s) => s.user,
  authToken: (s) => s.authToken,
  isLoggedIn: (s) => s.user !== null,
  authProgress: (s) => s.progress,
  roles: (s) => s.user === null ? [] : s.user.roles,
  privileges: (s) => s.user === null ? [] : s.user.privileges,
  mode: (s) => s === null ? '?' : s.info.mode,
  scannerOperator: (s) => s.scannerOperator
}

const actions = {
  async initialise ({ commit }) {
    commit('updateProgress', 'Acquiring tenant information...')
    commit('setInfo', await ArcAPI.info())

    commit('updateProgress', 'Initialising authentication...')
    commit('clearUser')

    commit('updateProgress', 'Checking for cached authentication token...')
    const storedAuthToken = window.localStorage.getItem(TOKEN_LOCAL_STORAGE_NAME)
    if (storedAuthToken) {
      commit('updateProgress', 'Testing cached authentication token...')
      try {
        const result = await ArcAPI.auth.check_auth(storedAuthToken)

        commit('updateProgress', 'Authentication token was renewed.')
        const token = result.token
        commit('setAuthToken', token)
        window.localStorage.setItem(TOKEN_LOCAL_STORAGE_NAME, token)

        commit('updateProgress', 'Acquiring active user context...')
        commit('setUser', await ArcAPI.auth.get_self())
      } catch {
        commit('updateProgress', 'Cached authentication token is NOT valid.  Authentication required.')
        window.localStorage.removeItem(TOKEN_LOCAL_STORAGE_NAME)
      }
    } else {
      commit('updateProgress', 'No cached token.')
    }
  },
  async authenticate ({ commit }, credentials) {
    const result = await ArcAPI.auth.authenticate_user(credentials)

    const token = result.token
    commit('setAuthToken', token)
    window.localStorage.setItem(TOKEN_LOCAL_STORAGE_NAME, token)

    commit('setUser', await ArcAPI.auth.get_self())
    return result
  },
  async logout ({ commit }) {
    commit('clearUser')
    commit('clearAuthToken')

    window.localStorage.removeItem(TOKEN_LOCAL_STORAGE_NAME)
    return Promise.resolve()
  },
  async activateScanner({ commit }, { code }) {
    const result = await ArcAPI.scanner.activate(code)
    commit('setScannerData', result)

    return result
  },
  clearScanner({ commit }) {
    commit('clearScannerData')
  }
}

const mutations = {
  setInfo (s, v) {
    s.info = v
  },
  setUser (s, v) {
    s.user = v
  },
  setAuthToken (s, v) {
    s.authToken = v
  },
  clearUser (s) {
    s.user = null
  },
  clearAuthToken (s) {
    s.authToken = null
  },
  updateProgress (s, v) {
    console.log(v)
    s.progress = v
  },
  setScannerData (s, v) {
    s.scannerAuthToken = v.token
    s.scannerOperator = v.operator
  },
  clearScannerData (s) {
    s.scannerAuthToken = null
    s.scannerOperator = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
