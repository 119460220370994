<template>
  <searchable-object-selector ref="selector" :objects="sortedSuppliers" :disabled="disabled" :placeholder="placeholder"
    @input="input" :loading="$apollo.loading" :value="value" />
</template>

<script>
import gql from 'graphql-tag';

export default {
  name: 'supplier-selector',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default() {
        return false;
      }
    },
    disabled: {
      Boolean,
      default() {
        return false;
      }
    },
    size: String,
    placeholder: String
  },
  apollo: {
    companies: gql`query {
      companies { suppliers { id, name } }
    }`
  },
  computed: {
    sortedSuppliers() {
      return this.companies ? this.companies.suppliers.slice().sort((a, b) => a.name.localeCompare(b.name)) : [];
    }
  },
  methods: {
    input(v) {
      this.$emit('input', v);
      this.$emit('change', v);
    },
    selectById(id) {
      this.$refs.selector.selectById(id);
    }
  },
  mounted() {
    this.$apollo.queries.companies.refetch();
  }
};
</script>
