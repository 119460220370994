import requests from '../requests';
import migration from './migration';
import integration from './integration';

export default {
  migration,
  integration,
  log() {
    return requests.get('system/log');
  },
  export_log() {
    requests.download('system/log/export');
  },
  user_log(user, d) {
    return requests.get(`system/log/user/${user.id}?ts=${d}`);
  },
  usage_log() {
    return requests.get(`system/log/usage`);
  },
  config: {
    get() {
      return requests.get('system/config');
    },
    set(key, value) {
      return requests.put('system/config', { key, value });
    }
  },
  role_map: {
    list() {
      return requests.get('system/role-map');
    },
    privileges: {
      add(role, privilege) {
        return requests.post(`system/role-map/${role}/${privilege.id}`);
      },
      remove(role, privilege) {
        return requests.delete(`system/role-map/${role}/${privilege.id}`);
      }
    }
  },
  messages: {
    list() {
      return requests.get('system/messages');
    }
  }
};
