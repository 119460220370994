import requests from '../requests'

export default {
  authenticate_user (o) {
    return requests.post('auth/authenticate', o)
  },
  check_auth (token) {
    return requests.post('auth/check', { token })
  },
  get_self () {
    return requests.get('auth/self')
  },
  get_scanner_login() {
    return requests.get('auth/scanner-login')
  }
}
