import requests from '../requests';

export default {
  list(project) {
    return requests.get(`projects/${project.id}/workshop`);
  },
  get(project, id) {
    return requests.get(`projects/${project.id}/workshop/${id}`);
  },
  create(project, o) {
    return requests.post(`projects/${project.id}/workshop`, o);
  },
  update(project, o) {
    return requests.put(`projects/${project.id}/workshop/${o.id}`, o);
  },
  delete(project, o) {
    return requests.delete(`projects/${project.id}/workshop/${o.id}`);
  }
};
