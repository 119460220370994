import requests from '../requests'
import notes from './notes'
import types from './types'
import hires from './hires'
import subhires from './subhires'
import groups from './groups'
import quotes from './quotes'
import ngquotes from './ng-quotes'
import acl from './acl'
import logistics from './logistics'
import files from './files'
import members from './members'
import compliance from './compliance'
import links from './links'
import finance from './finance'
import schedule from './schedule'
import workshop from './workshop'

export default {
  list(withCompleted = false, withCancelled = false) {
    return requests.get(`projects/list?withCompleted=${withCompleted}&withCancelled=${withCancelled}`)
  },
  quick_list() {
    return requests.get('projects/quick-list')
  },
  get(id) {
    return requests.get(`projects/${id}`)
  },
  create(project) {
    return requests.post('projects', project)
  },
  update(project) {
    return requests.put(`projects/${project.id}`, project)
  },
  delete(project) {
    return requests.delete(`projects/${project.id}`)
  },
  change_status(project, status) {
    return requests.post(`projects/${project.id}/status`, { status })
  },
  change_status_reason(project, status, reason) {
    return requests.post(`projects/${project.id}/status`, { status, reason })
  },
  log(id) {
    return requests.get(`projects/${id}/log`)
  },
  tt_sync(project) {
    return requests.post(`projects/${project.id}/tt-sync`)
  },
  gc_sync(project) {
    return requests.post(`projects/${project.id}/gc-sync`)
  },
  price_list(policy) {
    return requests.get(`projects/price-list/${policy.id}`)
  },
  export_price_list(policy) {
    return requests.download(`projects/price-list/${policy.id}/export`)
  },
  jobs: {
    list(project) {
      return requests.get(`projects/${project.id}/warehouse-jobs`)
    }
  },
  contracts: {
    list(project) {
      return requests.get(`projects/${project.id}/contracts`)
    },
    get(project, id) {
      return requests.get(`projects/${project.id}/contracts/${id}`)
    },
    create(project, o) {
      return requests.post(`projects/${project.id}/contracts`, o)
    },
    update(project, o) {
      return requests.put(`projects/${project.id}/contracts/${o.id}`, o)
    },
    delete(project, o) {
      return requests.delete(`projects/${project.id}/contracts/${o.id}`)
    }
  },
  todo: {
    list(project) {
      return requests.get(`projects/${project.id}/todo`)
    }
  },
  notes,
  types,
  hires,
  groups,
  quotes,
  ngquotes,
  acl,
  subhires,
  logistics,
  files,
  members,
  compliance,
  links,
  finance,
  schedule,
  workshop,
  resource_lists: {
    list_tree(project) {
      return requests.get(`projects/${project.id}/resource-lists/tree`)
    },
    groups: {
      create(project, data) {
        return requests.post(`projects/${project.id}/resource-lists/groups`, data)
      }
    }
  }
}
