<template>
  <fi :icon="glyph" />
</template>

<script>
export default {
  props: {
    glyph: {
      type: String,
      required: true
    },
    spin: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: ''
    },
    gap: {
      type: Boolean,
      default: false
    },
    brand: {
      type: Boolean,
      default: false
    },
    reg: {
      type: Boolean,
      default: false
    },
    title: String,
    unfixed: {
      type: Boolean,
      default: false
    }
  }
  /* functional: true,
  render (createElement, context) {
    const classes = [
      context.props.brand !== false ? 'fab' : (context.props.reg !== false ? 'far' : 'fas'),
      context.props.unfixed !== false ? '' : 'fa-fw',
      `fa-${context.props.glyph}`
    ]

    if (context.props.spin !== false) {
      classes.push('fa-spin')
    }

    switch (context.props.variant) {
      case 'primary':
        classes.push('text-primary')
        break
      case 'danger':
        classes.push('text-danger')
        break
      case 'warning':
        classes.push('text-warning')
        break
      case 'success':
        classes.push('text-success')
        break
      case 'info':
        classes.push('text-info')
        break
    }

    if (context.props.gap !== false) {
      classes.push('mr-2')
    }

    const classList = classes.join(' ')
    return createElement('i', { attrs: { class: classList, title: context.props.title } })
  },
  install (Vue) {
    Vue.component('icon', this)
  } */
}
</script>
