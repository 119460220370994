import methodStatementTemplates from './method-statement-templates'
import riskAssessmentTemplates from './risk-assessment-templates'
import terms from './terms'
import certifications from './certifications'

export default {
  method_statement_templates: methodStatementTemplates,
  risk_assessment_templates: riskAssessmentTemplates,
  terms,
  certifications
}
