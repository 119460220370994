import requests from '../../requests'
import groups from './groups'

export default {
  groups,
  list (project) {
    return requests.get(`projects/${project.id}/hires`)
  },
  create (project, obj) {
    return requests.post(`projects/${project.id}/hires`, obj)
  },
  create_quote (project, obj) {
    return requests.post(`projects/${project.id}/hires/quote`, obj)
  },
  transfers: {
    list (project) {
      return requests.get(`projects/${project.id}/hires/transfers`)
    }
  }
}
