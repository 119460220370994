export default [
  {
    path: '/finance/business-orders/:id/edit',
    name: 'EditBusinessOrder',
    component: () => import('../../views/app/finance/orders/BusinessOrderEditor.vue')
  },
  {
    path: '/finance/business-orders/:id/payments',
    component: () => import('../../components/util/Placeholder.vue')
  },
  {
    path: '/finance/nca/:applId',
    name: 'NCAReview',
    component: () => import('../../views/app/finance/nca/review/index.vue')
  },
  {
    path: '/finance/accounts/charge-accounts/:accountId',
    name: 'charge-account-editor',
    component: () => import('../../views/app/finance/accounts/charge-accounts/Editor.vue')
  },
  {
    path: '/finance/accounts/nominal-accounts/:accountId',
    name: 'nominal-account-editor',
    component: () => import('../../views/app/finance/accounts/nominal-accounts/Editor.vue')
  },
  {
    path: '/finance/accounts/payment-accounts/:accountId',
    name: 'payment-account-editor',
    component: () => import('../../views/app/finance/accounts/payment-accounts/Editor.vue')
  },
  {
    path: '/finance/paydocs/:payDocId',
    name: 'PayDocEditor',
    component: () => import('../../views/app/finance/paydocs/editor/index.vue')
  },
  {
    path: '/finance',
    component: () => import('../../views/app/finance/index.vue'),
    redirect: { name: 'FinanceDashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'FinanceDashboard',
        component: () => import('../../views/app/finance/dashboard/index.vue')
      },
      {
        path: 'mail',
        name: 'FinanceMail',
        component: () => import('../../views/app/finance/mail/index.vue')
      },
      {
        path: 'purchase-orders',
        component: () => import('../../views/app/finance/order-controller/Purchase.vue')
      },
      {
        path: 'sales-orders',
        component: () => import('../../views/app/finance/order-controller/Sales.vue')
      },
      {
        path: 'pricing',
        component: () => import('../../views/app/finance/pricing/index.vue')
      },
      {
        path: 'ledger',
        component: () => import('../../views/app/finance/ledger/index.vue')
      },
      {
        path: 'invoices',
        component: () => import('../../views/app/finance/invoices/index.vue')
      },
      {
        path: 'nca',
        name: 'NCA',
        component: () => import('../../views/app/finance/nca/index.vue')
      },
      {
        path: 'paydocs',
        name: 'paydocs',
        component: () => import('../../views/app/finance/paydocs/index.vue')
      },
      {
        path: 'accounts',
        name: 'accounts',
        component: () => import('../../views/app/finance/accounts/index.vue'),
        redirect: { name: 'finance-accounts-charge' },
        children: [
          {
            path: 'charge-accounts',
            name: 'finance-accounts-charge',
            component: () => import('../../views/app/finance/accounts/charge-accounts/index.vue')
          },
          {
            path: 'nominal-accounts',
            name: 'finance-accounts-nominal',
            component: () => import('../../views/app/finance/accounts/nominal-accounts/index.vue')
          },
          {
            path: 'payment-accounts',
            name: 'finance-accounts-payment',
            component: () => import('../../views/app/finance/accounts/payment-accounts/index.vue')
          },
          {
            path: 'internal-transactions',
            name: 'finance-accounts-internal-txns',
            component: () => import('../../views/app/finance/accounts/internal-transactions/index.vue')
          }
        ]
      },
      {
        path: 'analysis',
        name: 'finance-analysis',
        component: () => import('../../views/app/finance/analysis/index.vue'),
        redirect: { name: 'finance-analysis-forecast' },
        children: [
          {
            path: 'forecast',
            name: 'finance-analysis-forecast',
            component: () => import('../../views/app/finance/analysis/forecast/index.vue')
          }
        ]
      }
    ]
  }
]
