import requests from '../../requests'

export default {
  list (pid, qid, aid, gid) {
    return requests.get(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${gid}/lines`)
  },
  get (pid, qid, aid, gid, lid) {
    return requests.get(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${gid}/lines/${lid}`)
  },
  create (pid, qid, aid, gid, obj) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${gid}/lines`, obj)
  },
  update (pid, qid, aid, gid, obj) {
    return requests.put(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${gid}/lines/${obj.id}`, obj)
  },
  delete (pid, qid, aid, gid, lid) {
    return requests.delete(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${gid}/lines/${lid}`)
  },
  moveTo (pid, qid, aid, gid, fromLID, toLID) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${gid}/lines/${fromLID}/move-to?id=${toLID}`)
  },
  copy_to (pid, qid, aid, gid, lid, dest) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${gid}/lines/${lid}/copy-to`, dest)
  }
}
