export default [
  {
    path: '/warehouse',
    component: () => import('../../views/app/warehouse/index.vue'),
    redirect: { name: 'WarehouseDashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'WarehouseDashboard',
        component: () => import('../../views/app/warehouse/dashboard/index.vue')
      },
      {
        path: 'assets',
        component: () => import('../../views/app/warehouse/assets/index.vue')
      },
      {
        path: 'availability-checker',
        component: () => import('../../views/app/warehouse/availability-checker/index.vue'),
        meta: {
          mode: 'week'
        }
      },
      {
        path: 'analysis',
        component: () => import('../../views/app/warehouse/analysis/index.vue'),
        redirect: { name: 'orphaned-reservations' },
        children: [
          {
            path: 'orphaned-reservations',
            name: 'orphaned-reservations',
            component: () => import('../../views/app/warehouse/analysis/orphaned-reservations/index.vue')
          },
          {
            path: 'cloned-reservations',
            name: 'cloned-reservations',
            component: () => import('../../views/app/warehouse/analysis/cloned-reservations/index.vue')
          }
        ]
      },
      {
        path: 'items',
        component: () => import('../../views/app/warehouse/Items.vue')
      },
      {
        path: 'hires',
        component: () => import('../../views/app/warehouse/hires/index.vue'),
        redirect: { name: 'WarehouseHiresOperations' },
        children: [
          {
            path: 'lists',
            name: 'WarehouseHiresLists',
            component: () => import('../../views/app/warehouse/hires/lists/index.vue')
          },
          {
            path: 'operations',
            name: 'WarehouseHiresOperations',
            component: () => import('../../views/app/warehouse/hires/operations/index.vue')
          },
          {
            path: 'transfers',
            name: 'WarehouseHiresTransfers',
            component: () => import('../../views/app/warehouse/hires/transfers/index.vue')
          },
          {
            path: 'aggregate-return',
            name: 'WarehouseHiresAggregateReturn',
            component: () => import('../../views/app/warehouse/hires/aggregate-return/index.vue')
          },
          {
            path: 'scanner',
            name: 'WarehouseHiresScanner',
            component: () => import('../../views/app/warehouse/hires/scanner/index.vue')
          }
        ]
      },
      {
        path: 'hires/transfers/approve/:hireId',
        name: 'WarehouseHiresApproveTransfers',
        component: () => import('../../views/app/warehouse/hires/transfers/TransferApprovalEditor.vue')
      },
      {
        path: 'hires/aggregate-return/:arlId',
        name: 'WarehouseHiresAggregateReturnOperator',
        component: () => import('../../views/app/warehouse/hires/aggregate-return/operator/index.vue')
      },
      {
        path: 'subhires',
        component: () => import('../../views/app/warehouse/Subhires.vue')
      },
      {
        path: 'testing-repairs',
        component: () => import('../../views/app/warehouse/testing-repairs/index.vue')
      },
      {
        path: 'stock-check',
        component: () => import('../../views/app/warehouse/stock-check/index.vue')
      },
      {
        path: 'reports',
        component: () => import('../../views/app/warehouse/reports/index.vue')
      },
      {
        path: 'returns',
        component: () => import('../../views/app/warehouse/returns/index.vue')
      },
      {
        path: 'asset-problems-old',
        component: () => import('../../views/app/warehouse/asset-problems-old/index.vue')
      },
      {
        path: 'asset-problems',
        component: () => import('../../views/app/warehouse/asset-problems/index.vue'),
        redirect: { name: 'ProblemList' },
        children: [
          {
            path: 'list',
            name: 'ProblemList',
            component: () => import('../../views/app/warehouse/asset-problems/list/index.vue'),
          },
          {
            path: 'mail',
            component: () => import('../../views/app/warehouse/asset-problems/mail/index.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/warehouse/subhires/:id',
    name: 'WarehouseSubhireEditor',
    component: () => import('../../views/app/warehouse/SubhireEditor.vue')
  },
  {
    path: '/warehouse/hires/operations/:id',
    name: 'WarehouseHiresOperationsModify',
    component: () => import('../../views/app/warehouse/hires/operations/modify/index.vue')
  },
  {
    path: '/warehouse/assets/:id',
    component: () => import('../../views/app/warehouse/assets/AssetEditor.vue'),
    name: 'asset-editor',
    redirect: { name: 'AssetEditorStock' },
    children: [
      {
        name: 'AssetEditorStock',
        path: 'stock',
        component: () => import('../../views/app/warehouse/assets/stock/index.vue')
      },
      {
        path: 'tracked',
        component: () => import('../../views/app/warehouse/assets/tracked/index.vue')
      },
      {
        path: 'attributes',
        component: () => import('../../views/app/warehouse/assets/AssetEditorAttributes.vue')
      },
      {
        path: 'items',
        component: () => import('../../views/app/warehouse/assets/AssetEditorItems.vue')
      },
      {
        path: 'testing',
        component: () => import('../../components/util/Placeholder.vue')
      },
      {
        path: 'reservations',
        component: () => import('../../views/app/warehouse/assets/AssetEditorReservations.vue')
      },
      {
        path: 'pricing',
        component: () => import('../../views/app/warehouse/assets/pricing/index.vue')
      },
      {
        path: 'adjustments',
        name: 'AssetEditorAdjustments',
        component: () => import('../../views/app/warehouse/assets/adjustments/index.vue')
      },
      {
        path: 'problems',
        name: 'AssetEditorProblems',
        component: () => import('../../views/app/warehouse/assets/problems/index.vue')
      }
    ]
  },
  {
    path: '/warehouse/items/:id',
    component: () => import('../../views/app/warehouse/item-editor/index.vue'),
    name: 'ItemEditor',
    redirect: { name: 'ItemEditorConfig' },
    children: [
      {
        name: 'ItemEditorConfig',
        path: 'config',
        component: () => import('../../views/app/warehouse/item-editor/config/index.vue')
      },
      {
        path: 'pricing',
        name: 'ItemEditorPricing',
        component: () => import('../../views/app/warehouse/item-editor/pricing/index.vue')
      },
      {
        path: 'suppliers',
        component: () => import('../../views/app/warehouse/item-editor/ItemEditorSuppliers.vue')
      },
      {
        path: 'accessories',
        component: () => import('../../views/app/warehouse/item-editor/ItemEditorAccessories.vue')
      },
      {
        path: 'availability',
        component: () => import('../../views/app/warehouse/item-editor/ItemEditorAvailability.vue')
      },
      {
        path: 'prep-sheet',
        component: () => import('../../components/util/Placeholder.vue')
      }
    ]
  }
];
