import requests from '../../requests'
import lines from './lines'

export default {
  lines,
  list () {
    return requests.get('subhires')
  },
  get (id) {
    return requests.get(`subhires/${id}`)
  },
  update (subhire) {
    return requests.put(`subhires/${subhire.id}`, subhire)
  },
  delete (subhire) {
    return requests.delete(`subhires/${subhire.id}`)
  },
  eligible_pos (subhire) {
    return requests.get(`subhires/${subhire.id}/eligible-pos`)
  },
  create (obj) {
    return requests.post('subhires', obj)
  },
  confirm (subhire) {
    return requests.post(`subhires/${subhire.id}/confirm`)
  },
  unconfirm (subhire) {
    return requests.delete(`subhires/${subhire.id}/confirm`)
  },
  link_po (subhire, po) {
    return requests.post(`subhires/${subhire.id}/po/${po.id}`)
  },
  create_and_link_po (subhire) {
    return requests.post(`subhires/${subhire.id}/po`)
  },
  unlink_po (subhire) {
    return requests.delete(`subhires/${subhire.id}/po`)
  }
}
