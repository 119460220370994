import requests from '../../requests'
import groups from './groups'

export default {
  list (pid, qid) {
    return requests.get(`projects/${pid}/quotations/${qid}/aspects`)
  },
  get (pid, qid, aid) {
    return requests.get(`projects/${pid}/quotations/${qid}/aspects/${aid}`)
  },
  create (pid, qid, obj) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects`, obj)
  },
  update (pid, qid, obj) {
    return requests.put(`projects/${pid}/quotations/${qid}/aspects/${obj.id}`, obj)
  },
  delete (pid, qid, aid) {
    return requests.delete(`projects/${pid}/quotations/${qid}/aspects/${aid}`)
  },
  moveTo (pid, qid, fromAID, toAID) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects/${fromAID}/move-to?id=${toAID}`)
  },
  copy_to (pid, qid, aid, dest) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects/${aid}/copy-to`, dest)
  },
  groups
}
