import requests from '../requests'

export default {
  list () {
    return requests.get('projects/groups')
  },
  get (id) {
    return requests.get(`projects/groups/${id}`)
  },
  create (group) {
    return requests.post('projects/groups', group)
  },
  update (group) {
    return requests.put(`projects/groups/${group.id}`, group)
  },
  delete (group) {
    return requests.delete(`projects/groups/${group.id}`)
  },
  archive (group) {
    return requests.post(`projects/groups/${group.id}/archive`)
  },
  unarchive (group) {
    return requests.delete(`projects/groups/${group.id}/archive`)
  }
}
