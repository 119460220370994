import requests from '../requests'

export default {
  list (p) {
    return requests.get(`projects/${p.id}/members`)
  },
  get (p, m) {
    return requests.get(`projects/${p.id}/members/${m.id}`)
  },
  add (p, m, o) {
    return requests.post(`projects/${p.id}/members/${m.id}`, o)
  },
  update (p, m, o) {
    return requests.put(`projects/${p.id}/members/${m.id}`, o)
  },
  delete (p, m) {
    return requests.delete(`projects/${p.id}/members/${m.id}`)
  },
  self_assign (p) {
    return requests.post(`projects/${p.id}/members/self-assign`)
  }
}
