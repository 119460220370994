import requests from '../requests';

export default {
  list() {
    return requests.get('people');
  },
  list_short() {
    return requests.get('people?short=true');
  },
  list_in_group(code) {
    return requests.get(`people?group=${code}`);
  },
  phone_number_types() {
    return requests.get('meta/trivial/phone-number-types');
  },
  check_email_address(obj) {
    return requests.post('people/check-email-address', obj);
  },
  get(id) {
    return requests.get(`people/${id}`);
  },
  get_roles(p) {
    return requests.get(`people/${p.id}/roles`);
  },
  create(obj) {
    return requests.post('people', obj);
  },
  update(obj) {
    return requests.put(`people/${obj.id}`, obj);
  },
  delete(obj) {
    return requests.delete(`people/${obj.id}`);
  },
  add_phone_number(pid, type, obj) {
    return requests.post(`people/${pid}/phone-numbers/${type}`, obj);
  },
  remove_phone_number(pid, type) {
    return requests.delete(`people/${pid}/phone-numbers/${type}`);
  },
  roles: {
    list(p) {
      return requests.get(`people/${p.id}/roles`);
    },
    add(p, roleName) {
      return requests.post(`people/${p.id}/roles/${roleName}`);
    },
    remove(p, roleName) {
      return requests.delete(`people/${p.id}/roles/${roleName}`);
    }
  },
  notifications: {
    list(p) {
      return requests.get(`people/${p.id}/notifications`);
    },
    add(p, notificationName) {
      return requests.post(`people/${p.id}/notifications`, { code: notificationName });
    },
    remove(p, notificationName) {
      return requests.delete(`people/${p.id}/notifications/${notificationName}`);
    },
    send_test(p) {
      return requests.post(`people/${p.id}/notification-test`);
    }
  },
  new_customer_applications: {
    list(p) {
      return requests.get(`people/${p.id}/new-customer-applications`);
    },
    invite(p) {
      return requests.post(`people/${p.id}/new-customer-applications`);
    },
    resend_invite(p, i) {
      return requests.post(`people/${p.id}/new-customer-applications/${i.id}/resend`);
    },
    delete_application(p, i) {
      return requests.delete(`people/${p.id}/new-customer-applications/${i.id}`);
    }
  }
};
