import requests from '../../../requests'

export default {
  groups: {
    list (p) {
      return requests.get(`projects/${p.id}/logistics/crewing/groups`)
    },
    get (p, id) {
      return requests.get(`projects/${p.id}/logistics/crewing/groups/${id}`)
    },
    create (p, g) {
      return requests.post(`projects/${p.id}/logistics/crewing/groups`, g)
    },
    update (p, g) {
      return requests.put(`projects/${p.id}/logistics/crewing/groups/${g.id}`, g)
    },
    delete (p, g) {
      return requests.delete(`projects/${p.id}/logistics/crewing/groups/${g.id}`)
    },
    requests: {
      make_request (p, g, o) {
        return requests.post(`projects/${p.id}/logistics/crewing/groups/${g.id}/requests`, o)
      },
      update (p, g, r) {
        return requests.put(`projects/${p.id}/logistics/crewing/groups/${g.id}/requests/${r.id}`, r)
      },
      remove (p, g, r) {
        return requests.delete(`projects/${p.id}/logistics/crewing/groups/${g.id}/requests/${r.id}`)
      },
      approve (p, g, r) {
        return requests.post(`projects/${p.id}/logistics/crewing/groups/${g.id}/requests/${r.id}/approve`)
      },
      unapprove (p, g, r) {
        return requests.delete(`projects/${p.id}/logistics/crewing/groups/${g.id}/requests/${r.id}/approve`)
      },
      make_booking (p, g, r) {
        return requests.post(`projects/${p.id}/logistics/crewing/groups/${g.id}/requests/${r.id}/booking`)
      },
      dissociate_booking (p, g, r) {
        return requests.delete(`projects/${p.id}/logistics/crewing/groups/${g.id}/requests/${r.id}/booking`)
      }
    }
  },
  bookings: {
    list (p) {
      return requests.get(`projects/${p.id}/logistics/crewing/bookings`)
    }
  }
}
