<template>
  <searchable-object-selector
      ref="selector"
      :objects="sortedVenues"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="input"
      :value="value" />
</template>

<script>
export default {
  name: 'venue-selector',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default () {
        return false
      }
    },
    disabled: {
      Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String
  },
  data () {
    return {
      venues: []
    }
  },
  computed: {
    pathedVenues () {
      return this.venues.map(v => ({
        id: v.id,
        name: v.path.reverse().join(' > ')
      }))
    },
    sortedVenues () {
      return this.pathedVenues.slice().sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    selectById (id) {
      this.$refs.selector.selectById(id)
    }
  },
  async mounted () {
    this.venues = await ArcAPI.venues.list()
  }
}
</script>
