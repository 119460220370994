import requests from '../../requests'

export default {
  list (project) {
    return requests.get(`projects/${project.id}/quotations`)
  },
  create (project, o) {
    return requests.post(`projects/${project.id}/quotations`, o)
  },
  import (project, o) {
    return requests.post(`projects/${project.id}/quotations/import/${o.id}`, o)
  },
  get (project, qid) {
    return requests.get(`projects/${project.id}/quotations/${qid}`)
  },
  update (project, quote) {
    return requests.put(`projects/${project.id}/quotations/${quote.id}`, quote)
  },
  delete (project, quote) {
    return requests.delete(`projects/${project.id}/quotations/${quote.id}`)
  },
  contents (project, quote) {
    return requests.get(`projects/${project.id}/quotations/${quote.id}/v2/contents`)
  },
  compute_total (project, quote) {
    return requests.get(`projects/${project.id}/quotations/${quote.id}/v2/total`)
  },
  export_csv (project, quote) {
    return requests.download(`projects/${project.id}/quotations/${quote.id}/v2/export?csv`)
  },
  groups: {
    add_top_group (project, quote, o) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/v2/groups`, o)
    },
    add_child_group (project, quote, grp, o) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/v2/groups/${grp.id}/child-groups`, o)
    },
    add_line (project, quote, grp, o) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/v2/groups/${grp.id}/lines`, o)
    },
    update_group (project, quote, grp) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/v2/groups/${grp.id}`, grp)
    },
    remove_group (project, quote, grp) {
      return requests.delete(`projects/${project.id}/quotations/${quote.id}/v2/groups/${grp.id}`)
    },
    copy (project, quote, grp, o) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/v2/groups/${grp.id}/copy`, o)
    },
    move_up (project, quote, grp) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/v2/groups/${grp.id}/move-up`)
    },
    move_down (project, quote, grp) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/v2/groups/${grp.id}/move-down`)
    }
  },
  lines: {
    update (project, quote, line) {
      return requests.put(`projects/${project.id}/quotations/${quote.id}/v2/lines/${line.id}`, line)
    },
    remove (project, quote, line) {
      return requests.delete(`projects/${project.id}/quotations/${quote.id}/v2/lines/${line.id}`)
    },
    copy (project, quote, line, o) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/v2/lines/${line.id}/copy`, o)
    },
    move_to (project, quote, line, o) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/v2/lines/${line.id}/move-to`, o)
    },
    comments: {
      list (project, quote, line) {
        return requests.get(`projects/${project.id}/quotations/${quote.id}/v2/lines/${line.id}/comments`)
      },
      add (project, quote, line, o) {
        return requests.post(`projects/${project.id}/quotations/${quote.id}/v2/lines/${line.id}/comments`, o)
      },
      remove (project, quote, line, o) {
        return requests.delete(`projects/${project.id}/quotations/${quote.id}/v2/lines/${line.id}/comments/${o.id}`)
      }
    }
  },
  external_access: {
    list (project, quote) {
      return requests.get(`projects/${project.id}/quotations/${quote.id}/external-access`)
    },
    add (project, quote, person, o) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/external-access/${person.id}`, o)
    },
    remove (project, quote, person) {
      return requests.delete(`projects/${project.id}/quotations/${quote.id}/external-access/${person.id}`)
    },
    update (project, quote, person, o) {
      return requests.put(`projects/${project.id}/quotations/${quote.id}/external-access/${person.id}`, o)
    },
    get_log (project, quote, person) {
      return requests.get(`projects/${project.id}/quotations/${quote.id}/external-access/${person.id}/log`)
    },
    send_notification (project, quote, person) {
      return requests.post(`projects/${project.id}/quotations/${quote.id}/external-access/${person.id}/notify`)
    }
  }
}
