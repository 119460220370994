import requests from '../../requests'

export default {
  list () {
    return requests.get('warehouse/jobs/returns')
  },
  list_jobs (p) {
    return requests.get(`warehouse/jobs/for-project/${p.id}`)
  },
  get (id) {
    return requests.get(`warehouse/jobs/returns/${id}`)
  },
  create (o) {
    return requests.post('warehouse/jobs/returns', o)
  },
  delete (l) {
    return requests.delete(`warehouse/jobs/returns/${l.id}`)
  },
  start_return (l) {
    return requests.post(`warehouse/jobs/returns/${l.id}/start`)
  },
  mark_remaining (l) {
    return requests.post(`warehouse/jobs/returns/${l.id}/mark-remaining`)
  },
  clear_all (l) {
    return requests.post(`warehouse/jobs/returns/${l.id}/clear-all`)
  },
  complete (l) {
    return requests.post(`warehouse/jobs/returns/${l.id}/complete`)
  },
  complete_jobs (l) {
    return requests.post(`warehouse/jobs/returns/${l.id}/complete-jobs`)
  }
}
