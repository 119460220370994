import requests from '../../requests'

export default {
  list (project) {
    return requests.get(`projects/${project.id}/subhires`)
  },
  allocation: {
    list (project) {
      return requests.get(`projects/${project.id}/subhires/allocation`)
    },
    eligible_for_item (project, hire, item) {
      return requests.get(`projects/${project.id}/subhires/allocation/${hire.id}/${item.id}/eligible-subhires`)
    },
    allocate (project, hire, item, data) {
      return requests.post(`projects/${project.id}/subhires/allocation/${hire.id}/${item.id}`, data)
    },
    allocate_new (project, hire, item, data) {
      return requests.post(`projects/${project.id}/subhires/allocation/${hire.id}/${item.id}/new`, data)
    },
    deallocate (project, hire, item, line, settings) {
      return requests.delete(`projects/${project.id}/subhires/allocation/${hire.id}/${item.id}/${line.id}`, settings)
    }
  }
}
