<template>
  <div class="d-flex align-items-center" :title="title">
    <div v-if="icon !== undefined && icon !== null" :class="containerClass">
      <fi :icon="icon" class="mr-2" fixed-width :spin="shouldSpin" />
    </div>
    <div><slot /></div>
  </div>
</template>

<script>
export default {
  props: ['icon', 'icon-variant', 'spin', 'inline', 'title'],
  computed: {
    shouldSpin () {
      return this.spin !== undefined && this.spin !== false
    },
    shouldInline () {
      return this.inline !== undefined && this.inline !== false
    },
    containerClass () {
      return this.iconVariant !== undefined ? `text-${this.iconVariant}` : ''
    }
  }
}
</script>
