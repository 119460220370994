import requests from '../requests'

export default {
  list() {
    return requests.get(`enquiries`)
  },
  get(id) {
    return requests.get(`enquiries/${id}`)
  },
  create(obj) {
    return requests.post(`enquiries`, obj)
  },
  update(obj) {
    return requests.put(`enquiries/${obj.id}`, obj)
  },
  delete(obj) {
    return requests.delete(`enquiries/${obj.id}`)
  }
}
