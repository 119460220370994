<template>
  <searchable-object-selector
      ref="selector"
      :objects="sortedPeople"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="input"
      :formatter="formatContact"
      :value="value" />
</template>

<script>
export default {
  name: 'contact-selector',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default () {
        return false
      }
    },
    disabled: {
      Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String,
    company: Object
  },
  data () {
    return {
      people: []
    }
  },
  watch: {
    async company (c) {
      await this.reload()

      if (c !== null) {
        const primaryContact = this.people.find(co => co.primary)
        if (primaryContact === undefined || primaryContact === null) {
          this.selectById(null)
        } else {
          this.selectById(primaryContact.id)
        }
      }
    }
  },
  computed: {
    sortedPeople () {
      return this.people.slice().sort((a, b) => a.last_name.localeCompare(b.last_name))
    }
  },
  methods: {
    async reload () {
      if (this.company === null) {
        this.people = []
      } else {
        this.people = await ArcAPI.companies.contacts.list(this.company)
      }
    },
    formatContact (f) {
      return `${f.type}: ${f.name}`
    },
    input (v) {
      this.$emit('input', v)
    },
    selectById (id) {
      this.$refs.selector.selectById(id)
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
