<template>
  <b-overlay :show="loading" spinner-small>
    <v-select
        class="sos2"
        :options="options"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="input"
        :value="selectedObjectId"
        :state="state" />
  </b-overlay>
</template>

<style scoped>
>>> {
  --vs-font-size: 10pt;
}
</style>

<script>
import vSelect from 'vue-select'

export default {
  name: 'searchable-object-selector',
  components: { vSelect },
  props: {
    objects: Array,
    value: Object,
    state: {
      Boolean,
      default () {
        return null
      }
    },
    formatter: {
      type: Function,
      default (v) {
        return v.name
      }
    },
    allowNull: {
      Boolean,
      default () {
        return false
      }
    },
    disabled: {
      Boolean,
      default () {
        return false
      }
    },
    loading: {
      Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String
  },
  data () {
    return {
      selectedObjectId: null
    }
  },
  computed: {
    options () {
      return this.objects.map(p => {
        return {
          label: this.formatter(p),
          value: p.id
        }
      })
    }
  },
  watch: {
    value (v) {
      this.resync()
    },
    objects () {
      this.resync()
    }
  },
  methods: {
    input (v) {
      if (typeof v === 'undefined' || v === null || v === '') {
        this.$emit('input', null)
      } else {
        this.$emit('input', this.lookupObjectFromId(v.value))
      }

      this.selectedObjectId = v || null
    },
    lookupObjectFromId (id) {
      return this.objects.find(p => p.id === id)
    },
    selectById (id) {
      this.selectedObjectId = this.options.find(o => o.value === id) || null
      this.$emit('input', this.lookupObjectFromId(id))
    },
    resync () {
      this.selectedObjectId = (typeof this.value === 'undefined' || this.value === null) ? null : this.options.find(o => o.value === this.value.id)
    }
  },
  mounted () {
    this.resync()
  }
}
</script>
