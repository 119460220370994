export default [
  {
    path: '/projects',
    component: () => import('../../views/app/projects/index.vue'),
    redirect: { name: 'ProjectsList' },
    children: [
      {
        path: 'list',
        name: 'ProjectsList',
        component: () => import('../../views/app/projects/List.vue')
      },
      {
        path: 'unassigned',
        name: 'ProjectsListUnassigned',
        component: () => import('../../views/app/projects/List.vue')
      },
      {
        path: 'groups',
        component: () => import('../../views/app/projects/Groups.vue')
      },
      {
        path: 'schedule',
        component: () => import('../../views/app/projects/Schedule.vue')
      },
      {
        path: 'price-list',
        component: () => import('../../views/app/projects/price-list/index.vue')
      },
      {
        path: 'asset-availability',
        component: () => import('../../views/app/warehouse/availability-checker/index.vue')
      },
      {
        path: 'enquiries',
        component: () => import('../../views/app/projects/enquiries/index.vue')
      },
      {
        path: 'quotable-elements',
        component: () => import('../../views/app/projects/quotable-elements/index.vue'),
        name: 'QuotableElements'
      },
    ]
  },
  {
    path: '/projects/creator',
    name: 'ProjectCreator',
    component: () => import('../../views/app/projects/creator/index.vue')
  },
  {
    path: '/projects/:pid/schedule/general/:eid',
    component: () => import('../../views/app/project-editor/schedule/ScheduleEntryEditor.vue')
  },
  {
    path: '/projects/:pid/compliance/hs/risk-assessments/:raid',
    component: () => import('../../views/app/project-editor/compliance/hs/RiskAssessmentEditor.vue')
  },
  {
    path: '/projects/:pid/compliance/hs/method-statements/:msid',
    component: () => import('../../views/app/project-editor/compliance/hs/MethodStatementEditor.vue')
  },
  {
    path: '/projects/:id',
    name: 'ProjectEditor',
    component: () => import('../../views/app/project-editor/index.vue'),
    redirect: { name: 'ProjectOverview' },
    children: [
      {
        name: 'ProjectOverview',
        path: 'overview',
        component: () => import('../../views/app/project-editor/overview/index.vue'),
        redirect: { name: 'ProjectOverviewGeneral' },
        children: [
          {
            name: 'ProjectOverviewGeneral',
            path: 'general',
            component: () => import('../../views/app/project-editor/overview/general/index.vue')
          },
          {
            name: 'ProjectOverviewManagement',
            path: 'management',
            component: () => import('../../views/app/project-editor/overview/management/index.vue')
          },
          {
            name: 'ProjectOverviewLog',
            path: 'log',
            component: () => import('../../views/app/project-editor/overview/log/index.vue')
          }
        ]
      },
      {
        name: 'ProjectWorkflow',
        path: 'workflow',
        component: () => import('../../views/app/project-editor/workflow/index.vue')
      },
      {
        path: 'reminders',
        component: () => import('../../views/app/project-editor/reminders/index.vue'),
        redirect: { name: 'ProjectReminderNotes' },
        children: [
          {
            name: 'ProjectReminderNotes',
            path: 'notes',
            component: () => import('../../views/app/project-editor/reminders/notes/index.vue')
          },
          {
            name: 'ProjectReminderMessages',
            path: 'messages',
            component: () => import('../../views/app/project-editor/reminders/messages/index.vue')
          },
          {
            name: 'ProjectReminderTodo',
            path: 'todo',
            component: () => import('../../views/app/project-editor/reminders/todo/index.vue')
          }
        ]
      },
      {
        path: 'quotes',
        component: () => import('../../views/app/project-editor/quotations/index.vue')
      },
      {
        path: 'contracts',
        name: 'project-editor-contracts',
        component: () => import('../../views/app/project-editor/contracts/index.vue')
      },
      {
        path: 'resources',
        component: () => import('../../views/app/project-editor/resource-planner/index.vue')
      },
      {
        path: 'workshop',
        component: () => import('../../views/app/project-editor/workshop/index.vue'),
        redirect: { name: 'ProjectWorkshopJobs' },
        children: [
          {
            name: 'ProjectWorkshopJobs',
            path: 'jobs',
            component: () => import('../../views/app/project-editor/workshop/jobs/index.vue')
          }
        ]
      },
      {
        path: 'equipment',
        component: () => import('../../views/app/project-editor/equipment/index.vue'),
        redirect: { name: 'ProjectEditorEquipmentLists' },
        children: [
          {
            name: 'ProjectEditorEquipmentLists',
            path: 'lists',
            component: () => import('../../views/app/project-editor/equipment/EquipmentLists.vue')
          },
          {
            name: 'ProjectEditorEquipmentJobs',
            path: 'jobs',
            component: () => import('../../views/app/project-editor/equipment/jobs/index.vue')
          },
          {
            name: 'ProjectEditorEquipmentTransfers',
            path: 'transfers',
            component: () => import('../../views/app/project-editor/equipment/transfers/index.vue')
          },
          {
            path: 'subhires',
            component: () => import('../../views/app/project-editor/equipment/subhires/index.vue'),
            redirect: { name: 'ProjectEditorEquipmentSubhiresAllocation' },
            children: [
              {
                name: 'ProjectEditorEquipmentSubhiresAllocation',
                path: 'allocation',
                component: () => import('../../views/app/project-editor/equipment/subhires/allocation/index.vue')
              },
              {
                name: 'ProjectEditorEquipmentSubhiresBookings',
                path: 'bookings',
                component: () => import('../../views/app/project-editor/equipment/subhires/bookings/index.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'schedule',
        component: () => import('../../views/app/project-editor/schedule/index.vue'),
        redirect: { name: 'ScheduleEditorGeneral' },
        children: [
          {
            name: 'ScheduleEditorGeneral',
            path: 'general',
            component: () => import('../../views/app/project-editor/schedule/GeneralSchedule.vue')
          },
          {
            path: 'event',
            component: () => import('../../views/app/project-editor/schedule/EventSchedule.vue')
          }
        ]
      },
      {
        path: 'compliance',
        component: () => import('../../views/app/project-editor/compliance/index.vue'),
        redirect: { name: 'HealthAndSafety' },
        children: [
          {
            name: 'HealthAndSafety',
            path: 'hs',
            component: () => import('../../views/app/project-editor/compliance/hs/index.vue'),
            redirect: { name: 'RiskAssessments' },
            children: [
              {
                name: 'RiskAssessments',
                path: 'risk-assessments',
                component: () => import('../../views/app/project-editor/compliance/hs/RiskAssessments.vue')
              },
              {
                path: 'method-statements',
                component: () => import('../../views/app/project-editor/compliance/hs/MethodStatements.vue')
              },
              {
                path: 'PPE',
                component: () => import('../../views/app/project-editor/compliance/hs/ppe/index.vue')
              },
              {
                path: 'safety-packs',
                component: () => import('../../views/app/project-editor/compliance/hs/safety-packs/index.vue')
              }
            ]
          },
          {
            path: 'certs',
            component: () => import('../../views/app/project-editor/compliance/certs/index.vue'),
            redirect: { name: 'EquipmentCerts' },
            children: [
              {
                name: 'EquipmentCerts',
                path: 'equipment',
                meta: { kind: 'equipment' },
                component: () => import('../../views/app/project-editor/compliance/certs/CertList.vue')
              },
              {
                path: 'personnel',
                meta: { kind: 'personnel' },
                component: () => import('../../views/app/project-editor/compliance/certs/CertList.vue')
              }
            ]
          },
          {
            path: 'licensing',
            component: () => import('../../components/util/Placeholder.vue')
          }
        ]
      },
      {
        path: 'logistics',
        component: () => import('../../views/app/project-editor/logistics/index.vue'),
        redirect: { name: 'LogisticsCrewing' },
        children: [
          {
            name: 'LogisticsCrewing',
            path: 'crewing',
            component: () => import('../../views/app/project-editor/logistics/crewing/index.vue'),
            redirect: { name: 'CrewRequests' },
            children: [
              {
                name: 'CrewRequests',
                path: 'requests',
                component: () => import('../../views/app/project-editor/logistics/crewing/requests/index.vue')
              },
              {
                path: 'bookings',
                component: () => import('../../views/app/project-editor/logistics/crewing/bookings/index.vue')
              }
            ]
          },
          {
            path: 'transport',
            component: () => import('../../views/app/project-editor/logistics/transport/index.vue'),
            redirect: { name: 'TransportJobs' },
            children: [
              {
                name: 'TransportJobs',
                path: 'jobs',
                component: () => import('../../views/app/project-editor/logistics/transport/TransportJobs.vue')
              },
              {
                path: 'schedule',
                component: () => import('../../views/app/project-editor/logistics/transport/schedule/index.vue')
              }
            ]
          },
          {
            path: 'accommodation',
            component: () => import('../../components/util/Placeholder.vue')
          }
        ]
      },
      {
        path: 'old-finance',
        component: () => import('../../views/app/project-editor/finance/index.vue'),
        redirect: { name: 'FinancePurchaseOrders' },
        children: [
          {
            name: 'FinancePurchaseOrders',
            path: 'purchase-orders',
            meta: { kind: 'purchase' },
            component: () => import('../../views/app/project-editor/finance/Orders.vue')
          },
          {
            path: 'sales-orders',
            meta: { kind: 'sales' },
            component: () => import('../../views/app/project-editor/finance/Orders.vue')
          },
          {
            path: 'ledger',
            component: () => import('../../views/app/project-editor/finance/Ledger.vue')
          },
          {
            path: 'analysis',
            component: () => import('../../views/app/project-editor/finance/Analysis.vue')
          },
          {
            path: 'costing',
            component: () => import('../../views/app/project-editor/finance/Costing.vue')
          },
          {
            path: 'expenses',
            component: () => import('../../components/util/Placeholder.vue')
          }
        ]
      },
      {
        path: 'finance',
        component: () => import('../../views/app/project-editor/finance2/index.vue'),
        redirect: { name: 'ProjectFinancePayDocs' },
        children: [
          {
            name: 'FinanceSchedule',
            path: 'schedule',
            component: () => import('../../views/app/project-editor/finance2/schedule/index.vue')
          },
          {
            name: 'ProjectFinancePayDocs',
            path: 'paydocs',
            component: () => import('../../views/app/project-editor/finance2/paydocs/index.vue')
          },
          {
            path: 'account',
            component: () => import('../../views/app/project-editor/finance2/account/index.vue')
          },
          {
            path: 'expenses',
            component: () => import('../../views/app/project-editor/finance2/expenses/index.vue')
          }
        ]
      },
      {
        path: 'documents',
        component: () => import('../../views/app/project-editor/docs/index.vue'),
        redirect: { name: 'ProjectEditorDocsFiles' },
        children: [
          {
            path: 'files',
            name: 'ProjectEditorDocsFiles',
            component: () => import('../../views/app/project-editor/docs/files/index.vue')
          },
          {
            path: 'document-pack',
            component: () => import('../../views/app/project-editor/docs/pack/index.vue')
          }
        ]
      },
      {
        path: 'acl',
        component: () => import('../../views/app/project-editor/acl/index.vue')
      },
      {
        path: 'links',
        component: () => import('../../views/app/project-editor/links/index.vue')
      }
    ]
  },
  {
    path: '/projects/:pid/quotes/:qid',
    component: () => import('../../views/app/ng-quote-editor/index.vue'),
    name: 'QuoteEditor'
  }
]
