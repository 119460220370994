import requests from '../requests'

export default {
  list_activities () {
    return requests.get('compliance/method-statement-templates/activities')
  },
  list_activity_tasks (activity) {
    return requests.get(`compliance/method-statement-templates/activities/${activity.id}/tasks`)
  },
  add_activity (activity) {
    return requests.post('compliance/method-statement-templates/activities', activity)
  },
  update_activity (activity) {
    return requests.put(`compliance/method-statement-templates/activities/${activity.id}`, activity)
  },
  delete_activity (activity) {
    return requests.delete(`compliance/method-statement-templates/activities/${activity.id}`)
  },
  add_task (activity, task) {
    return requests.post(`compliance/method-statement-templates/activities/${activity.id}/tasks`, task)
  },
  update_task (activity, task) {
    return requests.put(`compliance/method-statement-templates/activities/${activity.id}/tasks/${task.id}`, task)
  },
  delete_task (activity, task) {
    return requests.delete(`compliance/method-statement-templates/activities/${activity.id}/tasks/${task.id}`)
  },
  move_task_up (activity, task) {
    return requests.post(`compliance/method-statement-templates/activities/${activity.id}/tasks/${task.id}/move/up`)
  },
  move_task_down (activity, task) {
    return requests.post(`compliance/method-statement-templates/activities/${activity.id}/tasks/${task.id}/move/down`)
  }
}
