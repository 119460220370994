import requests from '../requests'

export default {
  list (company) {
    return requests.get(`companies/${company.id}/contacts`)
  },
  delete (company, contact) {
    return requests.delete(`companies/${company.id}/contacts/${contact.id}`)
  },
  update (company, contact) {
    return requests.put(`companies/${company.id}/contacts/${contact.id}`, contact)
  }
}
