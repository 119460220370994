import requests from '../../requests'

export default {
  reservations () {
    return requests.post('system/migration/reservations')
  },
  legacy_assets: {
    list () {
      return requests.get('system/migration/legacy-assets')
    },
    update (asset) {
      return requests.put(`system/migration/legacy-assets/${asset.id}`, asset)
    },
    delete (asset) {
      return requests.delete(`system/migration/legacy-assets/${asset.id}`)
    },
    create_trivial_item (asset) {
      return requests.post(`system/migration/legacy-assets/${asset.id}/trivial-item`)
    },
    import_assets (file) {
      const data = new FormData()
      data.append('file', file)

      return requests.post('system/migration/legacy-assets/import', data)
    },
    export_pricing_reports () {
      return requests.tur('exports/legacy-pricing')
    },
    export_all_pricing () {
      return requests.tur('exports/all-pricing')
    }
  }
}
