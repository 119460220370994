import requests from '../../requests'

export default {
  risk_assessments: {
    list (project) {
      return requests.get(`projects/${project.id}/compliance/risk-assessments`)
    },
    create (project) {
      return requests.post(`projects/${project.id}/compliance/risk-assessments`)
    },
    get (project, id) {
      return requests.get(`projects/${project.id}/compliance/risk-assessments/${id}`)
    },
    update (project, ra) {
      return requests.put(`projects/${project.id}/compliance/risk-assessments/${ra.id}`, ra)
    },
    delete (project, ra) {
      return requests.delete(`projects/${project.id}/compliance/risk-assessments/${ra.id}`)
    },
    approve (project, ra) {
      return requests.post(`projects/${project.id}/compliance/risk-assessments/${ra.id}/approve`)
    },
    unapprove (project, ra) {
      return requests.delete(`projects/${project.id}/compliance/risk-assessments/${ra.id}/approve`)
    },
    risks: {
      get (project, ra, id) {
        return requests.get(`projects/${project.id}/compliance/risk-assessments/${ra.id}/${id}`)
      },
      create (project, ra, o) {
        return requests.post(`projects/${project.id}/compliance/risk-assessments/${ra.id}`, o)
      },
      update (project, ra, o) {
        return requests.put(`projects/${project.id}/compliance/risk-assessments/${ra.id}/${o.id}`, o)
      },
      delete (project, ra, o) {
        return requests.delete(`projects/${project.id}/compliance/risk-assessments/${ra.id}/${o.id}`)
      }
    }
  },
  certifications: {
    add (project, o) {
      return requests.post(`projects/${project.id}/compliance/certifications/${o.id}`, o)
    }
  }
}
