import orders from './orders';
import ledger from './ledger';
import invoices from './invoices';
import assetPrices from './asset-prices';
import paydocs from './paydocs';
import chargeAccounts from './charge-accounts';
import nominalAccounts from './nominal-accounts';
import paymentAccounts from './payment-accounts';
import requests from '../requests';

export default {
  orders,
  asset_prices: assetPrices,
  ledger,
  invoices,
  paydocs,
  charge_accounts: chargeAccounts,
  nominal_accounts: nominalAccounts,
  payment_accounts: paymentAccounts,
  mail: {
    list(o) {
      return requests.getq('finance/mail', o);
    },
    get(id) {
      return requests.get(`finance/mail/${id}`);
    },
    update(o) {
      return requests.put(`finance/mail/${o.id}`, o);
    },
    preview_attachment(msg, att) {
      return requests.open(`finance/mail/${msg.id}/attachments/${att.index}?preview=true`);
    },
    assign(msg, o) {
      return requests.post(`finance/mail/${msg.id}/assign`, o);
    },
    unassign(msg) {
      return requests.delete(`finance/mail/${msg.id}/assign`);
    }
  },
  analysis: {
    forecast(criteria) {
      return requests.get(`finance/analysis/forecast?start=${criteria.start}&end=${criteria.end}&status=${criteria.status || 'any'}`);
    },
    forecast_export(criteria) {
      return requests.download(`finance/analysis/forecast?export=true&start=${criteria.start}&end=${criteria.end}&status=${criteria.status || 'any'}`);
    }
  },
  dashboard: {
    uncharged_projects() {
      return requests.get('finance/dashboard/uncharged-projects');
    }
  },
  new_customer_applications: {
    list() {
      return requests.get('finance/new-customer-applications');
    },
    invite(person) {
      return requests.post('finance/new-customer-applications', person);
    },
    get(id) {
      return requests.get(`finance/new-customer-applications/${id}`);
    },
    delete(appl) {
      return requests.delete(`finance/new-customer-applications/${appl.id}`);
    },
    resend_invitation(appl) {
      return requests.post(`finance/new-customer-applications/${appl.id}/resend-invitation`);
    },
    approve(appl, o) {
      return requests.post(`finance/new-customer-applications/${appl.id}/approve`, o);
    },
    revise(appl, o) {
      return requests.post(`finance/new-customer-applications/${appl.id}/revise`, o);
    },
    reject(appl, o) {
      return requests.post(`finance/new-customer-applications/${appl.id}/reject`, o);
    }
  }
};
