<template>
  <div class="d-flex">
    <b-datepicker v-model="dv" />
    <b-timepicker v-model="tv" class="ml-4" />
  </div>
</template>

<script>
import { format, formatISO, getDate, getHours, getMinutes, getMonth, getSeconds, getYear, parse, parseISO, set as pset } from 'date-fns'
import { enGB } from 'date-fns/locale'

export default {
  name: 'date-time-selector',
  props: ['value', 'disabled'],
  data: () => ({
    fullValue: new Date()
  }),
  computed: {
    isoValue () {
      return parseISO(this.value)
    },
    dv: {
      get () {
        return format(this.isoValue, 'yyyy-MM-dd', { locale: enGB })
      },
      set (v) {
        const targetValue = parse(v, 'yyyy-MM-dd', new Date())

        const currentValue = this.isoValue
        const newValue = pset(currentValue, {
          year: getYear(targetValue),
          month: getMonth(targetValue),
          date: getDate(targetValue)
        })

        this.$emit('input', formatISO(newValue))
      }
    },
    tv: {
      get () {
        return format(this.isoValue, 'kk:mm:ss', { locale: enGB })
      },
      set (v) {
        const targetValue = parse(v, 'kk:mm:Ss', new Date())

        const currentValue = this.isoValue
        const newValue = pset(currentValue, {
          hours: getHours(targetValue),
          minutes: getMinutes(targetValue),
          seconds: getSeconds(targetValue)
        })

        this.$emit('input', formatISO(newValue))
      }
    }
  }
}
</script>
