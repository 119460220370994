import Vue from 'vue';
import VueApollo from 'vue-apollo';

import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from "apollo-link";

import store from "../store/index.js";

Vue.use(VueApollo);

const httpLink = createHttpLink({ uri: "/api/v2/graphql" });

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = store.getters['auth/authToken'];

  operation.setContext({
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return forward(operation);
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
});

export default new VueApollo({
  defaultClient: apolloClient,
});
