<template>
  <searchable-object-selector
      ref="selector"
      :objects="sortedCodes"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="input"
      :value="value"
      :size="size"
      :formatter="formatter"
      :loading="loading" />
</template>

<script>
export default {
  name: 'nominal-code-selector',
  props: {
    value: Object,
    allowNull: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String,
    filter: {
      type: Function,
      default () {
        return (a) => true
      }
    }
  },
  data: () => ({
    nominalCodes: [],
    loading: false
  }),
  computed: {
    filteredCodes () {
      if (this.filter === null) {
        return this.nominalCodes
      } else {
        return this.nominalCodes.filter(this.filter)
      }
    },
    sortedCodes () {
      return this.filteredCodes.slice().sort((a, b) => a.code.localeCompare(b.code))
    }
  },
  methods: {
    async reload () {
      this.loading = true
      try {
        this.nominalCodes = await ArcAPI.meta.finance.nominal_codes.list()
      } finally {
        this.loading = false
      }
    },
    input (v) {
      this.$emit('input', v)
    },
    selectById (id) {
      this.$refs.selector.selectById(id)
    },
    formatter (nc) {
      return `${nc.code}: ${nc.description} (${nc.type})`
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
