import requests from '../../requests'

export default {
  permissions (id) {
    return requests.options(`finance/orders/${id}/payments`)
  },
  list (id) {
    return requests.get(`finance/orders/${id}/payments`)
  },
  add (oid, obj) {
    return requests.post(`finance/orders/${oid}/payments`, obj)
  },
  remove (oid, obj) {
    return requests.delete(`finance/orders/${oid}/payments/${obj.id}`)
  }
}
