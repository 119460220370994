import requests from '../requests'

export default {
  list() {
    return requests.get(`todo`)
  },
  get(id) {
    return requests.get(`todo/${id}`)
  },
  create(obj) {
    return requests.post(`todo`, obj)
  },
  update(obj) {
    return requests.put(`todo/${obj.id}`, obj)
  },
  delete(obj) {
    return requests.delete(`todo/${obj.id}`)
  }
}
