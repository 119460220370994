import requests from '../../../requests'

export default {
  list () {
    return requests.get('logistics/transport/schedules')
  },
  get (id) {
    return requests.get(`logistics/transport/schedules/${id}`)
  },
  create (o) {
    return requests.post('logistics/transport/schedules', o)
  },
  update (o) {
    return requests.put(`logistics/transport/schedules/${o.id}`, o)
  },
  delete (o) {
    return requests.delete(`logistics/transport/schedules/${o.id}`)
  },
  jobs: {
    list (s) {
      return requests.get(`logistics/transport/schedules/${s.id}/jobs`)
    },
    available (s) {
      return requests.get(`logistics/transport/schedules/${s.id}/jobs/available`)
    },
    create (s, j) {
      return requests.post(`logistics/transport/schedules/${s.id}/jobs`, j)
    },
    add (s, j) {
      return requests.post(`logistics/transport/schedules/${s.id}/jobs/${j.id}`)
    },
    remove (s, j) {
      return requests.delete(`logistics/transport/schedules/${s.id}/jobs/${j.id}`)
    }
  }
}
