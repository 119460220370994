import requests from '@/api2'
import aspects from './aspects'

export default {
  list (pid) {
    return requests.get(`projects/${pid}/quotations`)
  },
  get (pid, qid) {
    return requests.get(`projects/${pid}/quotations/${qid}`)
  },
  async delete (pid, qid) {
    return requests.delete(`projects/${pid}/quotations/${qid}`)
  },
  aspects
}
