import requests from '../requests';

export default {
  get(id) {
    return requests.get(`contracts/${id}`);
  },
  get_log(o) {
    return requests.get(`contracts/${o.id}/log`);
  },
  update(o) {
    return requests.put(`contracts/${o.id}`, o);
  },
  delete(o) {
    return requests.delete(`contracts/${o.id}`);
  },
  request_validation(contract, o) {
    return requests.post(`contracts/${contract.id}/validate/request`, o);
  },
  validate(contract) {
    return requests.post(`contracts/${contract.id}/validate`);
  },
  devalidate(contract) {
    return requests.delete(`contracts/${contract.id}/validate`);
  },
  projects: {
    list(contract) {
      return requests.get(`contracts/${contract.id}/projects`);
    },
    add(contract, project) {
      return requests.post(`contracts/${contract.id}/projects/${project.id}`);
    },
    remove(contract, project) {
      return requests.delete(`contracts/${contract.id}/projects/${project.id}`);
    }
  },
  quotations: {
    list(contract) {
      return requests.get(`contracts/${contract.id}/quotations`);
    },
    add(contract, quotation) {
      return requests.post(`contracts/${contract.id}/quotations/${quotation.id}`);
    },
    remove(contract, quotation) {
      return requests.delete(`contracts/${contract.id}/quotations/${quotation.id}`);
    }
  },
  payment_schedule: {
    list(contract) {
      return requests.get(`contracts/${contract.id}/payment-schedule`);
    },
    add(contract, demand) {
      return requests.post(`contracts/${contract.id}/payment-schedule`, demand);
    },
    remove(contract, demand) {
      return requests.delete(`contracts/${contract.id}/payment-schedule/${demand.id}`);
    },
    regenerate(contract) {
      return requests.post(`contracts/${contract.id}/payment-schedule/regenerate`, contract);
    }
  }
};
