import requests from '../../requests'

export default {
  list() {
    return requests.get('finance/payment-accounts')
  },
  recompute_balances() {
    return requests.post('finance/payment-accounts/recompute-balances')
  },
  get(id) {
    return requests.get(`finance/payment-accounts/${id}`)
  },
  create(o) {
    return requests.post('finance/payment-accounts', o)
  },
  update(o) {
    return requests.put(`finance/payment-accounts/${o.id}`, o)
  },
  delete(o) {
    return requests.delete(`finance/payment-accounts/${o.id}`)
  },
  transactions: {
    list(o) {
      return requests.get(`finance/payment-accounts/${o.id}/transactions`)
    }
  }
}
