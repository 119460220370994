import requests from '../../requests';
import returns from './returns';

export default {
  returns,
  list(filter) {
    return requests.getq('warehouse/jobs', filter);
  },
  get(id) {
    return requests.get(`warehouse/jobs/${id}`);
  },
  delete(o) {
    return requests.delete(`warehouse/jobs/${o.id}`);
  },
  start_pick(job) {
    return requests.post(`warehouse/jobs/${job.id}/picking`);
  },
  cancel_pick(job) {
    return requests.delete(`warehouse/jobs/${job.id}/picking`);
  },
  complete_pick(job, o) {
    return requests.post(`warehouse/jobs/${job.id}/picking/complete`, o);
  },
  auto_pick(job) {
    return requests.post(`warehouse/jobs/${job.id}/picking/all`);
  },
  clear_pick(job) {
    return requests.delete(`warehouse/jobs/${job.id}/picking/all`);
  },
  pick(job, item, element, o) {
    return requests.put(`warehouse/jobs/${job.id}/picking/${item.id}/${element.id}`, o);
  },
  unpick(job, item, element) {
    return requests.delete(`warehouse/jobs/${job.id}/picking/${item.id}/${element.id}`);
  },
  check_item(job, item) {
    return requests.post(`warehouse/jobs/${job.id}/picking/${item.id}/check`);
  },
  check(job, item, element) {
    return requests.post(`warehouse/jobs/${job.id}/picking/${item.id}/${element.id}/check`);
  },
  uncheck(job, item, element) {
    return requests.delete(`warehouse/jobs/${job.id}/picking/${item.id}/${element.id}/check`);
  },
  auto_check(job) {
    return requests.post(`warehouse/jobs/${job.id}/picking/check-all`);
  },
  pick_additional(job, item, o) {
    return requests.put(`warehouse/jobs/${job.id}/picking/${item.id}/additional`, o);
  },
  unpick_additional(job, item) {
    return requests.delete(`warehouse/jobs/${job.id}/picking/${item.id}/additional`);
  },
  compute_dispatch_weight(job) {
    return requests.get(`warehouse/jobs/${job.id}/dispatch/weight`);
  },
  extras: {
    add(job, asset, o) {
      return requests.post(`warehouse/jobs/${job.id}/picking/extras/${asset.id}`, o);
    },
    update(job, asset, o) {
      return requests.put(`warehouse/jobs/${job.id}/picking/extras/${asset.id}`, o);
    },
    remove(job, asset) {
      return requests.delete(`warehouse/jobs/${job.id}/picking/extras/${asset.id}`);
    },
    check_availability(job, asset) {
      return requests.get(`warehouse/jobs/${job.id}/picking/extras/${asset.id}/availability`);
    },
    ret(job, asset, o) {
      return requests.put(`warehouse/jobs/${job.id}/returning/extras/${asset.id}`, o);
    },
    unret(job, asset) {
      return requests.delete(`warehouse/jobs/${job.id}/returning/extras/${asset.id}`);
    }
  },
  substitutions: {
    add(job, item, element, asset, o) {
      return requests.post(`warehouse/jobs/${job.id}/picking/${item.id}/${element.id}/substitutions/${asset.id}`, o);
    },
    remove(job, item, element, asset) {
      return requests.delete(`warehouse/jobs/${job.id}/picking/${item.id}/${element.id}/substitutions/${asset.id}`);
    },
    ret(job, item, element, asset, o) {
      return requests.put(`warehouse/jobs/${job.id}/returning/${item.id}/${element.id}/substitutions/${asset.id}`, o);
    },
    unret(job, item, element, asset) {
      return requests.delete(`warehouse/jobs/${job.id}/returning/${item.id}/${element.id}/substitutions/${asset.id}`);
    }
  },
  dispatch(job) {
    return requests.post(`warehouse/jobs/${job.id}/dispatch`);
  },
  dispatch_group(group) {
    return requests.post(`warehouse/job-groups/${group.id}/dispatch`);
  },
  collect(job) {
    return requests.post(`warehouse/jobs/${job.id}/collect`);
  },
  collect_group(group) {
    return requests.post(`warehouse/job-groups/${group.id}/collect`);
  },
  start_prep_group(group) {
    return requests.post(`warehouse/job-groups/${group.id}/picking`);
  },
  ret_item(job, item, o) {
    return requests.put(`warehouse/jobs/${job.id}/returning/${item.id}`, o);
  },
  ret(job, item, element, o) {
    return requests.put(`warehouse/jobs/${job.id}/returning/${item.id}/${element.id}`, o);
  },
  get_ret(job, item, element) {
    return requests.get(`warehouse/jobs/${job.id}/returning/${item.id}/${element.id}`);
  },
  unret(job, item, element) {
    return requests.delete(`warehouse/jobs/${job.id}/returning/${item.id}/${element.id}`);
  },
  start_return(job) {
    return requests.post(`warehouse/jobs/${job.id}/returning`);
  },
  complete_return(job, o) {
    return requests.post(`warehouse/jobs/${job.id}/returning/complete`, o);
  },
  auto_return(job) {
    return requests.post(`warehouse/jobs/${job.id}/returning/all`);
  },
  clear_return(job) {
    return requests.delete(`warehouse/jobs/${job.id}/returning/all`);
  },
  complete(job) {
    return requests.post(`warehouse/jobs/${job.id}/complete`);
  },
  regroup(job) {
    return requests.post(`warehouse/jobs/${job.id}/regroup`);
  },
  assign_group(job, group) {
    return requests.post(`warehouse/jobs/${job.id}/regroup/${group.id}`);
  },
  ungroup(job) {
    return requests.delete(`warehouse/jobs/${job.id}/regroup`);
  },
  job_groups: {
    list() {
      return requests.get('warehouse/job-groups');
    },
    create(o) {
      return requests.post('warehouse/job-groups', o);
    },
    update(o) {
      return requests.put(`warehouse/job-groups/${o.id}`, o);
    },
    delete(o) {
      return requests.delete(`warehouse/job-groups/${o.id}`);
    }
  }
};
