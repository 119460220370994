<template>
  <searchable-object-selector ref="selector" :objects="sortedAssets" :disabled="disabled" :placeholder="placeholder"
    @input="input" :value="value" :size="size" :loading="loading" />
</template>

<script>
import ArcAPI from "../../ArcAPI";

export default {
  name: 'asset-selector',
  props: {
    value: Object,
    allowNull: {
      type: Boolean,
      default() {
        return false;
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    size: String,
    placeholder: String,
    filter: {
      type: Function,
      default() {
        return (a) => true;
      }
    }
  },
  data: () => ({
    assets: [],
    loading: false
  }),
  computed: {
    filteredAssets() {
      if (this.filter === null) {
        return this.assets;
      } else {
        return this.assets.filter(this.filter);
      }
    },
    sortedAssets() {
      return this.filteredAssets.slice().sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  methods: {
    async reload() {
      this.loading = true;
      try {
        this.assets = await ArcAPI.warehouse.assets.short_list();
      } finally {
        this.loading = false;
      }
    },
    input(v) {
      this.$emit('input', v);
    },
    selectById(id) {
      this.$refs.selector.selectById(id);
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
