<template>
  <searchable-object-selector
    ref="selector"
    :objects="sortedItems"
    :disabled="disabled"
    :placeholder="placeholder"
    @input="input"
    :value="value"
    :state="state"
  />
</template>

<script>
export default {
  name: 'selector-base',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default () {
        return false
      }
    },
    disabled: {
      Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String,
    state: {
      Boolean,
      default () {
        return null
      }
    },
    loader: Function,
    comparator: String
  },
  data: () => ({
    items: []
  }),
  computed: {
    sortedItems () {
      return this.items.slice().sort((a, b) => a[this.comparator].localeCompare(b[this.comparator]))
    }
  },
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    selectById (id) {
      this.$refs.selector.selectById(id)
    }
  },
  async mounted () {
    console.log(this.loader)
    this.items = await this.loader()
  }
}
</script>
