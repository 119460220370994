<template>
  <vue-date-range-picker
    v-model="selectedRange"
    class="db"
    opens="right"
    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
  >
    <template v-slot:input="picker" style="display: block">
      <icon-text icon="calendar">{{ formatDate(picker.startDate) }} &rarr; {{ formatDate(picker.endDate) }}</icon-text>
    </template>
  </vue-date-range-picker>
</template>

<style scoped>
.db {
  display: block !important;
}
</style>

<script>
import VueDateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { formatAPIDate } from '@/filters'
import Moment from 'moment'

export default {
  name: 'date-range-picker',
  props: ['start', 'end'],
  components: { VueDateRangePicker },
  computed: {
    selectedRange: {
      get () {
        return {
          startDate: this.start,
          endDate: this.end
        }
      },
      set (v) {
        this.$emit('input-start', Moment(v.startDate))
        this.$emit('input-end', Moment(v.endDate))
      }
    }
  },
  methods: {
    formatDate (d) {
      return formatAPIDate(d)
    }
  }
}
</script>
