import requests from '../../requests'

export default {
  list (subhire) {
    return requests.get(`subhires/${subhire.id}/lines`)
  },
  add (subhire, obj) {
    return requests.post(`subhires/${subhire.id}/lines`, obj)
  },
  update (subhire, obj) {
    return requests.put(`subhires/${subhire.id}/lines/${obj.id}`, obj)
  },
  remove (subhire, obj) {
    return requests.delete(`subhires/${subhire.id}/lines/${obj.id}`)
  }
}
