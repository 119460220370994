<template>
  <searchable-object-selector
      ref="selector"
      :objects="sortedCategories"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="input"
      :value="value"
      :state="state" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'category-selector',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default () {
        return false
      }
    },
    disabled: {
      Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String,
    state: {
      Boolean,
      default () {
        return null
      }
    }
  },
  computed: {
    ...mapGetters({
      categories: 'meta/categories'
    }),
    sortedCategories () {
      return this.categories.slice().sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    selectById (id) {
      this.$refs.selector.selectById(id)
    }
  },
  mounted () {
    this.$store.dispatch('meta/load')
  }
}
</script>
