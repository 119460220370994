import Axios from 'axios';
import store from '../store';
import { v4 as uuidv4 } from 'uuid';

const BASE = '/api/v1';
const LOCAL_SESSION = uuidv4();

export default {
  getConfiguration(additionalHeaders = {}) {
    return {
      headers: {
        Authorization: `Bearer ${store.getters['auth/authToken']}`,
        'X-Local-Session': LOCAL_SESSION,
        ...additionalHeaders
      }
    };
  },
  async get(path) {
    return (await Axios.get(`${BASE}/${path}`, this.getConfiguration())).data;
  },
  async getq(path, qry) {
    const target = `${BASE}/${path}`;

    if (qry) {
      const qs = Object.keys(qry)
        .filter(k => qry[k] !== undefined)
        .map(k => [encodeURIComponent(k), encodeURIComponent(qry[k])].join('='))
        .join('&');

      return (await Axios.get(`${target}?${qs}`, this.getConfiguration())).data;
    } else {
      return (await Axios.get(`${target}`, this.getConfiguration())).data;
    }
  },
  async head(path) {
    return (await Axios.head(`${BASE}/${path}`, this.getConfiguration())).data;
  },
  async put(path, o) {
    return (await Axios.put(`${BASE}/${path}`, o, this.getConfiguration())).data;
  },
  async patch(path, o) {
    return (await Axios.patch(`${BASE}/${path}`, o, this.getConfiguration())).data;
  },
  async post(path, o) {
    return (await Axios.post(`${BASE}/${path}`, o, this.getConfiguration())).data;
  },
  async delete(path) {
    return (await Axios.delete(`${BASE}/${path}`, this.getConfiguration())).data;
  },
  activateBlob(data, type, filename = null) {
    const url = window.URL.createObjectURL(new Blob([data], { type }));

    if (filename === null) {
      const previewWindow = window.open(url);
      previewWindow.addEventListener('popstate', (event) => { console.log(event); });
    } else {
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;

      link.click();
    }
  },
  async download(path) {
    const data = (await Axios.get(`${BASE}/${path}`, {
      ...this.getConfiguration(),
      responseType: 'blob'
    }));

    let filename = 'exported-file';
    const contentDispositionHeader = data.headers['content-disposition'];
    if (contentDispositionHeader) {
      const dispositionParts = contentDispositionHeader.split(';', 2);
      if (dispositionParts.length === 2 && dispositionParts[0].toLowerCase() === 'attachment') {
        const filenameParts = dispositionParts[1].split('=', 2);
        if (filenameParts.length === 2 && filenameParts[0].toLowerCase() === 'filename') {
          filename = filenameParts[1];
        }
      }
    }

    const contentType = data.headers['content-type'] || 'text/plain';
    this.activateBlob(data.data, contentType, filename);
  },
  async open(path) {
    const data = (await Axios.get(`${BASE}/${path}`, {
      ...this.getConfiguration(),
      responseType: 'blob'
    }));

    const contentType = data.headers['content-type'] || 'text/plain';
    this.activateBlob(data.data, contentType);
  },
  async upload(path, files) {
    const formData = new FormData();

    let index = 0;
    for (const file of files) {
      formData.append("f" + (index++), file);
    }

    return (await Axios.post(`${BASE}/${path}`, formData, {
      ...this.getConfiguration({ 'Content-Type': 'multipart/form-data' })
    })).data;
  }
};
