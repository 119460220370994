import requests from '../requests';
import assets from './assets';
import subhires from './subhires';
import hires from './hires';
import jobs from './jobs';
import transfers from './transfers';

export default {
  assets,
  subhires,
  hires,
  jobs,
  transfers,
  availability: {
    assets() {
      return requests.get('warehouse/availability/assets');
    },
    lookup(asset, fromDate, toDate) {
      return requests.get(`warehouse/availability/assets/${asset.id}?from=${fromDate.toISOString()}&to=${toDate.toISOString()}`);
    }
  },
  scanner: {
    activities() {
      return requests.get('warehouse/scanner/activities');
    },
    get_code({ type, target, operator }) {
      return requests.post(`warehouse/scanner/code/${type}/${target}`, { operator });
    }
  },
  problems: {
    list(o) {
      return requests.getq('warehouse/problems', o);
    },
    register(o) {
      return requests.post('warehouse/problems', o);
    },
    get(id) {
      return requests.get(`warehouse/problems/${id}`);
    },
    list_messages(o) {
      return requests.get(`warehouse/problems/${o.id}/messages`);
    },
    post_message(p, o) {
      return requests.post(`warehouse/problems/${p.id}/messages`, o);
    },
    assign(p, o) {
      return requests.post(`warehouse/problems/${p.id}/assign`, o);
    },
    unassign(p) {
      return requests.delete(`warehouse/problems/${p.id}/assign`);
    },
    quick_resolve(p) {
      return requests.post(`warehouse/problems/${p.id}/resolve`, p);
    },
    allocate(p, o) {
      return requests.post(`warehouse/problems/${p.id}/allocate`, o);
    },
    mail: {
      list(o) {
        return requests.getq('warehouse/problems/mail', o);
      },
      get(id) {
        return requests.get(`warehouse/problems/mail/${id}`);
      },
      update(o) {
        return requests.put(`warehouse/problems/mail/${o.id}`, o);
      },
      preview_attachment(msg, att) {
        return requests.open(`warehouse/problems/mail/${msg.id}/attachments/${att.index}?preview=true`);
      }
    },
  }
};
