import requests from '../requests'

export default {
  list (project) {
    return requests.get(`projects/${project.id}/files`)
  },
  createGoogleDriveStructure (project) {
    return requests.post(`projects/${project.id}/files/google-drive-structure`)
  }
}
