import requests from '../requests'

export default {
  warehouse: {
    unfinalised_hires () {
      return requests.get('dashboards/warehouse/unfinalised-hires')
    },
    unresolved_subhires () {
      return requests.get('dashboards/warehouse/unresolved-subhires')
    },
    orphaned_reservations () {
      return requests.get('dashboards/warehouse/orphaned-reservations')
    },
    clear_orphaned_reservations () {
      return requests.delete('dashboards/warehouse/orphaned-reservations')
    },
    unapproved_transfers () {
      return requests.get('dashboards/warehouse/unapproved-transfers')
    }
  },
  transport: {
    unscheduled_jobs () {
      return requests.get('dashboards/transport/unscheduled-jobs')
    }
  },
  compliance: {
    unapproved_documents () {
      return requests.get('dashboards/compliance/unapproved-documents')
    }
  },
  personal: {
    my_projects () {
      return requests.get('dashboards/personal/my-projects')
    },
    my_orders () {
      return requests.get('dashboards/personal/my-orders')
    },
    unassigned_projects () {
      return requests.get('dashboards/personal/unassigned-projects')
    },
    unapproved_hires () {
      return requests.get('dashboards/personal/unapproved-hires')
    },
    unapproved_charge_transactions () {
      return requests.get('dashboards/personal/unapproved-charge-transactions')
    }
  }
}
