import transport from './transport'
import crewing from './crewing'

export default [
  {
    path: '/logistics',
    component: () => import('../../views/app/logistics/index.vue'),
    redirect: { name: 'LogisticsDashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'LogisticsDashboard',
        component: () => import('../../views/app/logistics/dashboard/index.vue')
      },
      ...transport,
      ...crewing
    ]
  }
]
