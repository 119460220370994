export default [
  {
    path: '/reports',
    component: () => import('../../views/reports/index.vue'),
    meta: { layout: 'ReportLayout' },
    children: [
      {
        path: 'quotation/:qid',
        component: () => import('../../views/reports/qv2/index.vue'),
        name: 'QuoteReport'
      },
      {
        path: 'project/:projectId/asset-report',
        component: () => import('../../views/reports/project/asset-report/index.vue'),
        name: 'ProjectAssetReport'
      },
      {
        path: 'business-order/:oid',
        name: 'OrderReport',
        component: () => import('../../views/reports/business-order/index.vue')
      },
      {
        path: 'hire/list/:id',
        component: () => import('../../views/reports/hire/list/index.vue'),
        meta: {
          per: 'hire'
        }
      },
      {
        path: 'hire/per-project/:id',
        component: () => import('../../views/reports/hire/list/index.vue'),
        meta: {
          per: 'project'
        }
      },
      {
        path: 'hire/per-group/:id',
        component: () => import('../../views/reports/hire/list/index.vue'),
        meta: {
          per: 'group'
        }
      },
      {
        path: 'hire/per-hire/:id',
        component: () => import('../../views/reports/hire/list/index.vue'),
        meta: {
          per: 'hire'
        }
      },
      {
        path: 'hire/picking-list/:hid',
        component: () => import('../../views/reports/hire/picking-list/index.vue')
      },
      {
        path: 'hire/delivery-note/:hid',
        component: () => import('../../views/reports/hire/delivery-note/index.vue')
      },
      {
        path: 'hire/price-check/:hid',
        component: () => import('../../views/reports/hire/price-check/index.vue')
      },
      {
        path: 'hire/weights/:hid',
        component: () => import('../../views/reports/hire/weights/index.vue')
      },
      {
        path: 'warehouse/subhire/:shid',
        component: () => import('../../views/reports/warehouse/subhire/index.vue')
      },
      {
        path: 'warehouse/job/:jobid',
        component: () => import('../../views/reports/warehouse/job/index.vue')
      },
      {
        path: 'warehouse/batch-job/:projectId',
        component: () => import('../../views/reports/warehouse/batch-job/index.vue')
      },
      {
        path: 'warehouse/group-job/:groupId',
        component: () => import('../../views/reports/warehouse/group-job/index.vue')
      },
      {
        path: 'warehouse/arl/:listid',
        component: () => import('../../views/reports/warehouse/arl/index.vue')
      },
      {
        path: 'warehouse/job-shortage/:jobid',
        component: () => import('../../views/reports/warehouse/job-shortage/index.vue')
      },
      {
        path: 'warehouse/transfer/in/:recipientJobId/:donorHireId',
        component: () => import('../../views/reports/warehouse/transfer/index.vue'),
        meta: {
          mode: 'in'
        }
      },
      {
        path: 'warehouse/transfer/out/:donorJobId/:recipientHireId',
        component: () => import('../../views/reports/warehouse/transfer/index.vue'),
        meta: {
          mode: 'out'
        }
      },
      {
        path: 'compliance/method-statement/:msid',
        component: () => import('../../views/reports/compliance/method-statement/index.vue')
      },
      {
        path: 'compliance/risk-assessment/:raid',
        component: () => import('../../views/reports/compliance/risk-assessment/index.vue')
      },
      {
        path: 'directors/stock-value',
        name: 'DirectorsStockValue',
        component: () => import('../../views/reports/directors/StockValue.vue')
      },
      {
        path: '*',
        component: () => import('../../views/reports/NotFound.vue')
      }
    ]
  }
]
