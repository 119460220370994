import requests from '../requests'

export default {
  list (project) {
    return requests.get(`projects/${project.id}/acl`)
  },
  get (project, uid) {
    return requests.get(`projects/${project.id}/acl/${uid}`)
  },
  remove (project, entry) {
    return requests.delete(`projects/${project.id}/acl/${entry.user.id}`)
  },
  update (project, entry) {
    return requests.put(`projects/${project.id}/acl/${entry.user.id}`, entry)
  },
  create (project, uid) {
    return requests.post(`projects/${project.id}/acl/${uid}`)
  }
}
