import r from '../requests.js'

export default {
  elements: {
    list() {
      return r.get('warehouse2/elements')
    }
  },
  units: {
    list() {
      return r.get('warehouse2/units')
    }
  }
}
