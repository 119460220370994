import requests from '../requests'

export default {
  list (pid) {
    return requests.get('projects/' + pid + '/notes')
  },
  count_unack (project) {
    return requests.get(`projects/${project.id}/notes/count-unack`)
  },
  add (pid, text) {
    return requests.post('projects/' + pid + '/notes', { text: text })
  },
  delete (pid, nid) {
    return requests.delete('projects/' + pid + '/notes/' + nid)
  },
  undelete (pid, nid) {
    return requests.post(`projects/${pid}/notes/${nid}`)
  },
  acknowledgers (pid, nid) {
    return requests.get('projects/' + pid + '/notes/' + nid + '/ack')
  },
  ack (pid, nid) {
    return requests.post('projects/' + pid + '/notes/' + nid + '/ack')
  },
  unack (pid, nid) {
    return requests.delete('projects/' + pid + '/notes/' + nid + '/ack')
  },
  generic: {
    read (pid) {
      return requests.get(`projects/${pid}/notes/generic`)
    },
    update (pid, text) {
      return requests.put(`projects/${pid}/notes/generic`, { text: text })
    },
    clear (pid, text) {
      return requests.delete(`projects/${pid}/notes/generic`)
    }
  }
}
