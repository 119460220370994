import requests from '../requests'

export default {
  list () {
    return requests.get('venues')
  },
  get (id) {
    return requests.get(`venues/${id}`)
  },
  create (venue) {
    return requests.post('venues', venue)
  },
  update (venue) {
    return requests.put(`venues/${venue.id}`, venue)
  },
  delete (venue) {
    return requests.delete(`venues/${venue.id}`)
  }
}
