import requests from '../../requests';

export default {
  list(filter) {
    const filterQueryParts = [];

    for (const key in filter) {
      filterQueryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`);
    }

    const filterQuery = `?${filterQueryParts.join('&')}`;
    return requests.get(`finance/paydocs${filterQuery}`);
  },
  get(id) {
    return requests.get(`finance/paydocs/${id}`);
  },
  get_related_project(o) {
    return requests.get(`finance/paydocs/${o.id}/related-project`);
  },
  delete(o) {
    return requests.delete(`finance/paydocs/${o.id}`);
  },
  list_associated_pos(pd) {
    return requests.get(`finance/paydocs/${pd.id}/associated-pos`);
  },
  contents: {
    list(paydoc) {
      return requests.get(`finance/paydocs/${paydoc.id}/contents`);
    },
    add(paydoc, o) {
      return requests.post(`finance/paydocs/${paydoc.id}/contents`, o);
    },
    delete(paydoc, o) {
      return requests.delete(`finance/paydocs/${paydoc.id}/contents/${o.id}`);
    },
    update(paydoc, o) {
      return requests.put(`finance/paydocs/${paydoc.id}/contents/${o.id}`, o);
    },
    move(paydoc, o, mvmt) {
      return requests.put(`finance/paydocs/${paydoc.id}/contents/${o.id}/move-${mvmt}`, o);
    }
  },
  get_log(pd) {
    return requests.get(`finance/paydocs/${pd.id}/log`);
  },
  queries: {
    list(pd) {
      return requests.get(`finance/paydocs/${pd.id}/queries`);
    },
    raise(pd, o) {
      return requests.post(`finance/paydocs/${pd.id}/queries`, o);
    },
    delete(pd, q) {
      return requests.delete(`finance/paydocs/${pd.id}/queries/${q.id}`);
    },
    resolve(pd, q, o) {
      return requests.post(`finance/paydocs/${pd.id}/queries/${q.id}/resolve`, o);
    },
    unresolve(pd, q) {
      return requests.delete(`finance/paydocs/${pd.id}/queries/${q.id}/resolve`);
    }
  },
  charges: {
    list(pd) {
      return requests.get(`finance/paydocs/${pd.id}/charges`);
    },
    approve(pd, charge) {
      return requests.post(`finance/paydocs/${pd.id}/charges/${charge.id}/approve`);
    }
  },
  nominal_transactions: {
    list(pd) {
      return requests.get(`finance/paydocs/${pd.id}/nominal-transactions`);
    }
  },
  payments: {
    list(pd) {
      return requests.get(`finance/paydocs/${pd.id}/payments`);
    },
    add(pd, o) {
      return requests.post(`finance/paydocs/${pd.id}/payments`, o);
    },
    remove(pd, o) {
      return requests.delete(`finance/paydocs/${pd.id}/payments/${o.id}`);
    }
  },
  attachments: {
    list(pd) {
      return requests.get(`finance/paydocs/${pd.id}/attachments`);
    },
    preview(pd, att) {
      return requests.open(`finance/paydocs/${pd.id}/attachments/${att.key}/preview`);
    },
    upload(pd, f) {
      return requests.upload(`finance/paydocs/${pd.id}/attachments`, [f]);
    },
    delete(pd, att) {
      return requests.delete(`finance/paydocs/${pd.id}/attachments/${att.key}`);
    }
  },
  create(o) {
    return requests.post('finance/paydocs', o);
  },
  create_so_from_quotes(o) {
    return requests.post('finance/paydocs/from-quotes', o);
  },
  update(o) {
    return requests.put(`finance/paydocs/${o.id}`, o);
  },
  delete(o) {
    return requests.delete(`finance/paydocs/${o.id}`);
  },
  approval: {
    submit(o) {
      return requests.post(`finance/paydocs/${o.id}/approval`);
    },
    cancel(o) {
      return requests.delete(`finance/paydocs/${o.id}/approval`);
    },
    rollback(o) {
      return requests.delete(`finance/paydocs/${o.id}/approved`);
    }
  },
  issue: {
    send(o, obj) {
      return requests.post(`finance/paydocs/${o.id}/issue`, obj);
    },
    permitted(o) {
      return requests.get(`finance/paydocs/${o.id}/issue/permitted`);
    }
  },
  convert(o, obj) {
    return requests.post(`finance/paydocs/${o.id}/convert`, obj);
  },
  cancel(o) {
    return requests.post(`finance/paydocs/${o.id}/cancel`);
  },
  sync_with_xero(o) {
    return requests.post(`finance/paydocs/${o.id}/sync/xero`);
  }
};
