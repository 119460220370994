export default [
  {
    path: '/companies/:id',
    component: () => import('../../views/app/companies/index.vue'),
    redirect: { name: 'CompanyEditorContacts' },
    children: [
      {
        name: 'CompanyEditorContacts',
        path: 'contacts',
        component: () => import('../../views/app/companies/Contacts.vue')
      },
      {
        path: 'client',
        component: () => import('../../views/app/companies/Client.vue')
      },
      {
        path: 'supported-venue',
        component: () => import('../../views/app/companies/SupportedVenue.vue')
      },
      {
        path: 'supplier',
        component: () => import('../../views/app/companies/Supplier.vue')
      },
      {
        path: 'freelancer',
        component: () => import('../../views/app/companies/Freelancer.vue')
      }
    ]
  }
]
