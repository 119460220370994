<template>
  <searchable-object-selector
      ref="selector"
      :objects="sortedPricingPolicies"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="input"
      :value="value" />
</template>

<script>
export default {
  name: 'pricing-policy-selector',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default () {
        return false
      }
    },
    disabled: {
      Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String
  },
  data () {
    return {
      policies: []
    }
  },
  computed: {
    sortedPricingPolicies () {
      return this.policies.slice().sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    selectById (id) {
      this.$refs.selector.selectById(id)
    }
  },
  async mounted () {
    this.policies = await ArcAPI.meta.pricing_policies.list()
  }
}
</script>
