import requests from '../../../requests'

export default {
  jobs: {
    list (p) {
      return requests.get(`projects/${p.id}/logistics/transport/jobs`)
    },
    get (p, id) {
      return requests.get(`projects/${p.id}/logistics/transport/jobs/${id}`)
    },
    create (p, j) {
      return requests.post(`projects/${p.id}/logistics/transport/jobs`, j)
    },
    update (p, j) {
      return requests.put(`projects/${p.id}/logistics/transport/jobs/${j.id}`, j)
    },
    delete (p, j) {
      return requests.delete(`projects/${p.id}/logistics/transport/jobs/${j.id}`)
    },
    deschedule (p, j) {
      return requests.delete(`projects/${p.id}/logistics/transport/jobs/${j.id}/schedule`)
    }
  },
  schedules: {
    list (p) {
      return requests.get(`projects/${p.id}/logistics/transport/schedules`)
    },
    get (p, id) {
      return requests.get(`projects/${p.id}/logistics/transport/schedules/${id}`)
    },
    create (p, j) {
      return requests.post(`projects/${p.id}/logistics/transport/schedules`, j)
    },
    update (p, j) {
      return requests.put(`projects/${p.id}/logistics/transport/schedules/${j.id}`, j)
    },
    delete (p, j) {
      return requests.delete(`projects/${p.id}/logistics/transport/schedules/${j.id}`)
    },
    jobs: {
      list (p, s) {
        return requests.get(`projects/${p.id}/logistics/transport/schedules/${s.id}/jobs`)
      },
      add (p, s, j) {
        return requests.post(`projects/${p.id}/logistics/transport/schedules/${s.id}/jobs/${j.id}`)
      },
      remove (p, s, j) {
        return requests.delete(`projects/${p.id}/logistics/transport/schedules/${s.id}/jobs/${j.id}`)
      }
    }
  }
}
