import requests from '../requests';
import categories from './categories';
import manufacturers from './manufacturers';
import pricingPolicies from './pricing-policies';
import stockPools from './stock-pools';
import certifications from './certifications';

export default {
  categories,
  manufacturers,
  pricing_policies: pricingPolicies,
  stock_pools: stockPools,
  certifications,
  get_project_types() {
    return requests.get('meta/project-types');
  },
  get_active_project_types() {
    return requests.get('meta/project-types?active');
  },
  payment_policies: {
    list() {
      return requests.get('meta/payment-policies');
    },
    get(id) {
      return requests.get(`meta/payment-policies/${id}`);
    },
    create(t) {
      return requests.post('meta/payment-policies', t);
    },
    update(t) {
      return requests.put(`meta/payment-policies/${t.id}`, t);
    },
    delete(t) {
      return requests.delete(`meta/payment-policies/${t.id}`);
    }
  },
  projects: {
    types: {
      list() {
        return requests.get('meta/project-types');
      },
      get(id) {
        return requests.get(`meta/project-types/${id}`);
      },
      create(t) {
        return requests.post('meta/project-types', t);
      },
      update(t) {
        return requests.put(`meta/project-types/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/project-types/${t.id}`);
      }
    },
    status_types: {
      list() {
        return requests.get('meta/project-status-types');
      },
      get(id) {
        return requests.get(`meta/project-status-types/${id}`);
      },
      create(o) {
        return requests.post('meta/project-status-types', o);
      },
      update(o) {
        return requests.put(`meta/project-status-types/${o.id}`, o);
      },
      delete(o) {
        return requests.delete(`meta/project-status-types/${o.id}`);
      }
    },
    note_types: {
      list() {
        return requests.get('meta/project-note-types');
      },
      get(id) {
        return requests.get(`meta/project-note-types/${id}`);
      },
      create(t) {
        return requests.post('meta/project-note-types', t);
      },
      update(t) {
        return requests.put(`meta/project-note-types/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/project-note-types/${t.id}`);
      }
    },
    todo_status_types: {
      list() {
        return requests.get('meta/todo-status-types');
      },
      get(id) {
        return requests.get(`meta/todo-status-types/${id}`);
      },
      create(t) {
        return requests.post('meta/todo-status-types', t);
      },
      update(t) {
        return requests.put(`meta/todo-status-types/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/todo-status-types/${t.id}`);
      }
    },
    member_types: {
      list() {
        return requests.get('meta/project-member-types');
      },
      get(id) {
        return requests.get(`meta/project-member-types/${id}`);
      },
      create(t) {
        return requests.post('meta/project-member-types', t);
      },
      update(t) {
        return requests.put(`meta/project-member-types/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/project-member-types/${t.id}`);
      }
    },
    schedule_entry_types: {
      list() {
        return requests.get('meta/schedule-entry-types');
      },
      get(id) {
        return requests.get(`meta/schedule-entry-types/${id}`);
      },
      create(t) {
        return requests.post('meta/schedule-entry-types', t);
      },
      update(t) {
        return requests.put(`meta/schedule-entry-types/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/schedule-entry-types/${t.id}`);
      }
    }
  },
  quotes: {
    aspect_types: {
      list() {
        return requests.get('meta/aspect-types');
      },
      get(id) {
        return requests.get(`meta/aspect-types/${id}`);
      },
      create(t) {
        return requests.post('meta/aspect-types', t);
      },
      update(t) {
        return requests.put(`meta/aspect-types/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/aspect-types/${t.id}`);
      }
    },
    group_types: {
      list() {
        return requests.get('meta/quote-group-types');
      },
      list_active() {
        return requests.get('meta/quote-group-types?active');
      },
      get(id) {
        return requests.get(`meta/quote-group-types/${id}`);
      },
      create(t) {
        return requests.post('meta/quote-group-types', t);
      },
      update(t) {
        return requests.put(`meta/quote-group-types/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/quote-group-types/${t.id}`);
      }
    }
  },
  finance: {
    nominal_codes: {
      list() {
        return requests.get('meta/finance/nominal-codes');
      },
      list_full() {
        return requests.get('meta/finance/nominal-codes/full');
      },
      create(code) {
        return requests.post(`meta/finance/nominal-codes/${code.code}`, code);
      },
      update(code) {
        return requests.put(`meta/finance/nominal-codes/${code.code}`, code);
      },
      delete(code) {
        return requests.delete(`meta/finance/nominal-codes/${code.code}`);
      },
      from_xero() {
        return requests.get('meta/finance/nominal-codes/from-xero');
      },
      sync_xero() {
        return requests.post('meta/finance/nominal-codes/sync-xero');
      }
    },
    cost_centres: {
      list() {
        return requests.get('meta/finance/cost-centres');
      },
      create(o) {
        return requests.post('meta/finance/cost-centres', o);
      },
      update(o) {
        return requests.put(`meta/finance/cost-centres/${o.id}`, o);
      },
      delete(o) {
        return requests.delete(`meta/finance/cost-centres/${o.id}`);
      }
    }
  },
  transport: {
    job_types: {
      list() {
        return requests.get('meta/transport-job-types');
      },
      get(id) {
        return requests.get(`meta/transport-job-types/${id}`);
      },
      create(t) {
        return requests.post('meta/transport-job-types', t);
      },
      update(t) {
        return requests.put(`meta/transport-job-types/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/transport-job-types/${t.id}`);
      }
    },
    vehicle_classes: {
      list() {
        return requests.get('meta/vehicle-classes');
      },
      get(id) {
        return requests.get(`meta/vehicle-classes/${id}`);
      },
      create(t) {
        return requests.post('meta/vehicle-classes', t);
      },
      update(t) {
        return requests.put(`meta/vehicle-classes/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/vehicle-classes/${t.id}`);
      }
    },
    insurance_policies: {
      list() {
        return requests.get('meta/vehicle-insurance-policies');
      },
      get(id) {
        return requests.get(`meta/vehicle-insurance-policies/${id}`);
      },
      create(t) {
        return requests.post('meta/vehicle-insurance-policies', t);
      },
      update(t) {
        return requests.put(`meta/vehicle-insurance-policies/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/vehicle-insurance-policies/${t.id}`);
      }
    }
  },
  company: {
    departments: {
      list() {
        return requests.get('meta/departments');
      },
      get(id) {
        return requests.get(`meta/departments/${id}`);
      },
      create(t) {
        return requests.post('meta/departments', t);
      },
      update(t) {
        return requests.put(`meta/departments/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/departments/${t.id}`);
      }
    },
    sites: {
      list() {
        return requests.get('meta/sites');
      },
      get(id) {
        return requests.get(`meta/sites/${id}`);
      },
      create(t) {
        return requests.post('meta/sites', t);
      },
      update(t) {
        return requests.put(`meta/sites/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/sites/${t.id}`);
      }
    },
    storage_locations: {
      list() {
        return requests.get('meta/storage-locations');
      },
      get(id) {
        return requests.get(`meta/storage-locations/${id}`);
      },
      create(t) {
        return requests.post('meta/storage-locations', t);
      },
      update(t) {
        return requests.put(`meta/storage-locations/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/storage-locations/${t.id}`);
      }
    }
  },
  freelancer_skills: {
    list() {
      return requests.get('meta/freelancer-skills');
    },
    add(o) {
      return requests.post('meta/freelancer-skills', o);
    },
    update(o) {
      return requests.put(`meta/freelancer-skills/${o.id}`, o);
    },
    delete(o) {
      return requests.delete(`meta/freelancer-skills/${o.id}`);
    }
  },
  privileges: {
    list() {
      return requests.get('meta/privileges');
    },
    role_map: {
      list() {
        return requests.get('meta/privileges/role-map');
      },
      add(role, privilege) {
        return requests.post(`meta/privileges/role-map/${role}/${privilege.id}`);
      },
      remove(role, privilege) {
        return requests.delete(`meta/privileges/role-map/${role}/${privilege.id}`);
      },
      members: {
        list(role) {
          return requests.get(`meta/privileges/role-map/${role}/members`);
        },
        remove(role, member) {
          return requests.delete(`meta/privileges/role-map/${role}/members/${member.id}`);
        }
      }
    }
  },
  notifications: {
    list() {
      return requests.get('meta/notifications');
    },
    sync() {
      return requests.post('meta/notifications/sync');
    }
  },
  costing: {
    types: {
      list() {
        return requests.get('meta/costing/types');
      },
      update(t) {
        return requests.put(`meta/costing/types/${t.id}`, t);
      },
      delete(t) {
        return requests.delete(`meta/costing/types/${t.id}`);
      }
    },
    classes: {
      add(o) {
        return requests.post('meta/costing/classes', o);
      },
      add_type(cls, o) {
        return requests.post(`meta/costing/classes/${cls.id}`, o);
      },
      update(o) {
        return requests.put(`meta/costing/classes/${o.id}`, o);
      },
      delete(o) {
        return requests.delete(`meta/costing/classes/${o.id}`);
      }
    }
  }
};
