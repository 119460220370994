export default {
  reset () {
    document.title = 'Catalyst Arc'
  },
  update (t) {
    document.title = `Arc: ${t}`
  },
  updateReport (t) {
    document.title = t
  }
}
