export default [
  {
    path: '/compliance',
    component: () => import('../../views/app/compliance/index.vue'),
    redirect: { name: 'ComplianceDashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'ComplianceDashboard',
        component: () => import('../../views/app/compliance/dashboard/index.vue')
      },
      {
        path: 'terms-and-conditions',
        name: 'Terms',
        component: () => import('../../views/app/compliance/terms/index.vue')
      },
      {
        path: 'terms-and-conditions/:tcid',
        name: 'TermsView',
        component: () => import('../../views/app/compliance/terms/View.vue')
      },
      {
        path: 'certifications',
        component: () => import('../../views/app/compliance/certifications/index.vue'),
        redirect: { name: 'CertificationCategories' },
        children: [
          {
            path: 'categories',
            name: 'CertificationCategories',
            component: () => import('../../views/app/compliance/certifications/CertificationCategories.vue')
          },
          {
            path: 'evidence',
            name: 'CertificationEvidence',
            component: () => import('../../views/app/compliance/certifications/CertificationEvidence.vue')
          }
        ]
      },
      {
        path: 'ra-templates',
        component: () => import('../../views/app/compliance/ra-templates/index.vue')
      },
      {
        path: 'ms-templates',
        component: () => import('../../views/app/compliance/ms-templates/index.vue')
      }
    ]
  }
]
