import { format, parseISO, formatISO } from 'date-fns'
import { enGB } from 'date-fns/locale'

function getDateValue(value) {
  return typeof value === 'string' ? parseISO(value) : value
}

export function dateFormatter(value, formatStr) {
  return format(getDateValue(value), formatStr, { locale: enGB })
}

export function formatAPIDate(value) {
  return dateFormatter(value, 'P')
}

export function formatAPIDateShortDay(value) {
  return dateFormatter(value, 'E P')
}

export function formatAPIDateShortDayAMPM(value) {
  return dateFormatter(value, 'E P a')
}

export function formatAPIDateDay(value) {
  return dateFormatter(value, 'EEEE P')
}

export function formatAPIDateDayAMPM(value) {
  return dateFormatter(value, 'EEEE P aaa')
}

export function formatAPIDateTime(value) {
  return dateFormatter(value, 'Pp')
}

export function formatAPIDateTimeS(value) {
  return dateFormatter(value, 'Ppp')
}

export function formatISODate(value) {
  return formatISO(getDateValue(value))
}

export function formatAPICurrency(value) {
  if (value === undefined) return '? (undef)'
  if (value === null) return '? (null)'
  return value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) // value.toFixed(2)
}

export function formatAPIWeight(value) {
  if (value === undefined) return '(undef) kg'
  if (value === null) return '(null) kg'
  return value.toFixed(3) + ' kg'
}

export function composePersonList(people) {
  return people.map(p => p.name).join(', ')
}

export function debounce(fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export function fileSizeFormatter(size) {
  const i = (size == 0) ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}
