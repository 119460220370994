import requests from '../requests'

export default {
  list (project) {
    return requests.get(`projects/${project.id}/links`)
  },
  add (project, target) {
    return requests.post(`projects/${project.id}/links/${target.id}`)
  },
  remove (project, target) {
    return requests.delete(`projects/${project.id}/links/${target.id}`)
  },
  get (project, target) {
    return requests.get(`projects/${project.id}/links/${target.id}`)
  }
}
