import requests from '../../requests'
import lines from './lines'

export default {
  list (pid, qid, aid) {
    return requests.get(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups`)
  },
  get (pid, qid, aid, gid) {
    return requests.get(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${gid}`)
  },
  create (pid, qid, aid, obj) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups`, obj)
  },
  update (pid, qid, aid, obj) {
    return requests.put(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${obj.id}`, obj)
  },
  delete (pid, qid, aid, gid) {
    return requests.delete(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${gid}`)
  },
  moveTo (pid, qid, aid, fromGID, toGID) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${fromGID}/move-to?id=${toGID}`)
  },
  moveUp (pid, qid, aid, grp) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${grp.id}/move-up`)
  },
  moveDown (pid, qid, aid, grp) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${grp.id}/move-down`)
  },
  copy_to (pid, qid, aid, gid, dest) {
    return requests.post(`projects/${pid}/quotations/${qid}/aspects/${aid}/groups/${gid}/copy-to`, dest)
  },
  lines
}
