import requests from '../../requests'

export default {
  list (asset) {
    return requests.get(`assets/${asset.id}/instances`)
  },
  create (asset, o) {
    return requests.post(`assets/${asset.id}/instances`, o)
  },
  get (asset, id) {
    return requests.get(`assets/${asset.id}/instances/${id}`)
  },
  transfer (asset, instance, o) {
    return requests.post(`assets/${asset.id}/instances/${instance.identifier}/transfer`, o)
  },
  delete_adjust (asset, instance, o) {
    return requests.post(`assets/${asset.id}/instances/${instance.identifier}/delete-adjust`, o)
  }
}
