<template>
  <searchable-object-selector
      ref="selector"
      :objects="sortedPeople"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="input"
      :loading="loading"
      :formatter="formatter"
      :value="value" />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'person-selector',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default () {
        return false
      }
    },
    disabled: {
      Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String
  },
  computed: {
    ...mapGetters({
      people: 'people/list',
      loading: 'people/loading'
    }),
    sortedPeople () {
      return this.people.slice().sort((a, b) => a.last_name.localeCompare(b.last_name))
    }
  },
  methods: {
    reload () {
      this.$store.dispatch('people/load')
    },
    input (v) {
      this.$emit('input', v)
    },
    selectById (id) {
      this.$refs.selector.selectById(id)
    },
    formatter (o) {
      return `${o.name} (${o.email_address})`
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
