import requests from '../../requests'

export default {
  list (p) {
    return requests.get(`projects/${p.id}/schedule`)
  },
  list_meta (p) {
    return requests.get(`projects/${p.id}/schedule/meta`)
  },
  get (p, id) {
    return requests.get(`projects/${p.id}/schedule/${id}`)
  },
  create (p, o) {
    return requests.post(`projects/${p.id}/schedule`, o)
  },
  update (p, o) {
    return requests.put(`projects/${p.id}/schedule/${o.id}`, o)
  },
  remove (p, o) {
    return requests.delete(`projects/${p.id}/schedule/${o.id}`)
  }
}
