export default [
  {
    path: '/config',
    component: () => import('../../views/app/config/index.vue'),
    redirect: '/config/people',
    children: [
      {
        path: 'people',
        component: () => import('../../views/app/config/people/index.vue')
      },
      {
        path: 'people-groups',
        component: () => import('../../views/app/config/PeopleGroups.vue')
      },
      {
        path: 'companies',
        component: () => import('../../views/app/config/Companies.vue')
      },
      {
        path: 'venues',
        component: () => import('../../views/app/config/Venues.vue')
      },
      {
        path: 'display-screens',
        component: () => import('../../views/app/config/display-screens/index.vue')
      },
      {
        path: 'metadata',
        component: () => import('../../views/app/config/metadata/index.vue'),
        redirect: '/config/metadata/taxonomy',
        children: [
          {
            path: 'stock',
            component: () => import('../../views/app/config/metadata/stock/index.vue'),
            redirect: { name: 'ConfigStockPools' },
            children: [
              {
                path: 'pools',
                name: 'ConfigStockPools',
                component: () => import('../../views/app/config/metadata/stock/pools/index.vue')
              },
              {
                path: 'transactions',
                name: 'ConfigStockTransaction',
                component: () => import('../../views/app/config/metadata/stock/transactions/index.vue')
              }
            ]
          },
          {
            path: 'contact-types',
            component: () => import('../../views/app/config/metadata/MetadataContactTypes.vue')
          },
          {
            path: 'taxonomy',
            component: () => import('../../views/app/config/metadata/taxonomy/index.vue'),
            redirect: { name: 'ConfigCategories' },
            children: [
              {
                path: 'categories',
                component: () => import('../../views/app/config/metadata/taxonomy/MetadataCategories.vue'),
                name: 'ConfigCategories'
              },
              {
                path: 'manufacturers',
                component: () => import('../../views/app/config/metadata/taxonomy/MetadataManufacturers.vue'),
                name: 'ConfigManufacturers'
              }
            ]
          },
          {
            path: 'projects',
            component: () => import('../../views/app/config/metadata/projects/index.vue'),
            redirect: { name: 'ConfigProjectTypes' },
            children: [
              {
                path: 'project-types',
                component: () => import('../../views/app/config/metadata/projects/ProjectTypes.vue'),
                name: 'ConfigProjectTypes'
              },
              {
                path: 'project-status-types',
                component: () => import('../../views/app/config/metadata/projects/ProjectStatusTypes.vue')
              },
              {
                path: 'project-note-types',
                component: () => import('../../views/app/config/metadata/projects/ProjectNoteTypes.vue')
              },
              {
                path: 'group-types',
                component: () => import('../../views/app/config/metadata/projects/QuoteGroupTypes.vue')
              }
            ]
          },
          {
            path: 'integration',
            component: () => import('../../views/app/config/metadata/integration/index.vue'),
            redirect: { name: 'ConfigIntegrationXero' },
            children: [
              {
                path: 'xero',
                component: () => import('../../views/app/config/metadata/integration/xero/index.vue'),
                name: 'ConfigIntegrationXero'
              },
              {
                path: 'xero/callback',
                component: () => import('../../views/app/config/metadata/integration/xero/Callback.vue')
              },
              {
                path: 'teamtrack',
                component: () => import('../../views/app/config/metadata/integration/teamtrack/index.vue'),
                name: 'ConfigIntegrationTeamTrack',
                redirect: { name: 'TeamTrackPeopleMap' },
                children: [
                  {
                    path: 'people-map',
                    name: 'TeamTrackPeopleMap',
                    component: () => import('../../views/app/config/metadata/integration/teamtrack/people-map/index.vue')
                  }
                ]
              },
            ]
          },
          {
            path: 'schedule-entry-types',
            component: () => import('../../views/app/config/metadata/MetadataScheduleEntryTypes.vue')
          },
          {
            path: 'transport',
            redirect: { name: 'TransportJobTypes' },
            component: () => import('../../views/app/config/metadata/transport/index.vue'),
            children: [
              {
                path: 'job-types',
                name: 'TransportJobTypes',
                component: () => import('../../views/app/config/metadata/transport/JobTypes.vue')
              },
              {
                path: 'vehicle-classes',
                component: () => import('../../views/app/config/metadata/transport/VehicleClasses.vue')
              },
              {
                path: 'vehicles',
                component: () => import('../../views/app/config/metadata/transport/Vehicles.vue')
              },
              {
                path: 'insurance-policies',
                component: () => import('../../views/app/config/metadata/transport/InsurancePolicies.vue')
              }
            ]
          },
          {
            path: 'company',
            redirect: { name: 'CompanyDepartments' },
            component: () => import('../../views/app/config/metadata/company/index.vue'),
            children: [
              {
                path: 'departments',
                name: 'CompanyDepartments',
                component: () => import('../../views/app/config/metadata/company/departments/index.vue')
              },
              {
                path: 'sites',
                component: () => import('../../views/app/config/metadata/company/sites/index.vue')
              },
              {
                path: 'storage-locations',
                component: () => import('../../views/app/config/metadata/company/storage-locations/index.vue')
              }
            ]
          },
          {
            path: 'freelancer-skills',
            component: () => import('../../views/app/config/metadata/MetadataFreelancerSkills.vue')
          },
          {
            path: 'finance',
            redirect: { name: 'Costing' },
            component: () => import('../../views/app/config/metadata/finance/index.vue'),
            children: [
              {
                path: 'costing',
                name: 'Costing',
                component: () => import('../../views/app/config/metadata/finance/MetadataCosting.vue')
              },
              {
                path: 'pricing-policies',
                component: () => import('../../views/app/config/metadata/finance/MetadataPricingPolicies.vue')
              },
              {
                path: 'nominal-codes',
                component: () => import('../../views/app/config/metadata/finance/MetadataNominalCodes.vue')
              },
              {
                path: 'cost-centres',
                name: 'cost-centres',
                component: () => import('../../views/app/config/metadata/finance/MetadataCostCentres.vue')
              }
            ]
          },
          {
            path: 'system',
            component: () => import('../../views/app/config/metadata/MetadataSystem.vue')
          },
          {
            path: 'authz',
            component: () => import('../../views/app/config/metadata/authz/index.vue')
          },
          {
            path: 'notifications',
            component: () => import('../../views/app/config/metadata/notifications/index.vue')
          }
        ]
      }
    ]
  }
]
