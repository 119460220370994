import requests from '../requests'

export default {
  list () {
    return requests.get('meta/categories')
  },
  get (id) {
    return requests.get(`meta/categories/${id}`)
  },
  create (obj) {
    return requests.post('meta/categories', obj)
  },
  update (obj) {
    return requests.put(`meta/categories/${obj.id}`, obj)
  },
  delete (obj) {
    return requests.delete(`meta/categories/${obj.id}`)
  }
}
