import requests from '../../requests'

import allocation from './allocation'
import payments from './payments'
import lines from './lines'

export default {
  allocation,
  payments,
  lines,
  list () {
    return requests.get('finance/orders')
  },
  list_purchase (filter) {
    const qry = '?' + filter.map(d => `${d}=1`).join('&')
    return requests.get(`finance/orders/purchase${qry}`)
  },
  list_sales (filter) {
    const qry = '?' + filter.map(d => `${d}=1`).join('&')
    return requests.get(`finance/orders/sales${qry}`)
  },
  get (id) {
    return requests.get(`finance/orders/${id}`)
  },
  issue_po (obj) {
    return requests.post('finance/orders/purchase', obj)
  },
  issue_so (obj) {
    return requests.post('finance/orders/sale', obj)
  },
  update (obj) {
    return requests.put(`finance/orders/${obj.id}`, obj)
  },
  approve (po) {
    return requests.post(`finance/orders/${po.id}/approve`)
  },
  unapprove (po) {
    return requests.delete(`finance/orders/${po.id}/approve`)
  },
  send (po) {
    return requests.post(`finance/orders/${po.id}/sent`)
  },
  cancel (po) {
    return requests.post(`finance/orders/${po.id}/cancel`)
  },
  reissue (po) {
    return requests.delete(`finance/orders/${po.id}/cancel`)
  },
  delete (po) {
    return requests.delete(`finance/orders/${po.id}`)
  },
  links (po) {
    return requests.get(`finance/orders/${po.id}/links`)
  },
  link_to_xero (po, o) {
    return requests.post(`finance/orders/${po.id}/link-to-xero`, o)
  }
}
