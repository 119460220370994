<template>
  <searchable-object-selector
      ref="selector"
      :objects="sortedSites"
      :disabled="disabled"
      :placeholder="placeholder"
      :formatter="format"
      @input="input"
      :value="value"
      :state="state" />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'site-selector',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default () {
        return false
      }
    },
    disabled: {
      Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String,
    state: {
      Boolean,
      default () {
        return null
      }
    }
  },
  computed: {
    ...mapGetters({
      sites: 'meta/sites'
    }),
    sortedSites () {
      return this.sites.slice().sort((a, b) => a.code.localeCompare(b.code))
    }
  },
  methods: {
    format: (i) => `${i.code}: ${i.name}`,
    input (v) {
      this.$emit('input', v)
    },
    selectById (id) {
      this.$refs.selector.selectById(id)
    }
  },
  mounted () {
    this.$store.dispatch('meta/load')
  }
}
</script>
