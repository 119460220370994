import requests from '../../requests'

export default {
  list () {
    return requests.get('warehouse/transfers')
  },
  approval (hire) {
    return requests.get(`warehouse/transfers/approval/${hire.id}`)
  },
  approve (hire) {
    return requests.post(`warehouse/transfers/approval/${hire.id}/approve`)
  },
  reject: {
    commit (recipientHire) {
      return requests.post(`warehouse/transfers/approval/${recipientHire.id}/reject`)
    },
    transfer (recipientHire, tfr, o) {
      return requests.post(`warehouse/transfers/approval/${recipientHire.id}/reject/transfer/${tfr.id}`, o)
    },
    item (recipientHire, donorHire, item, o) {
      return requests.post(`warehouse/transfers/approval/${recipientHire.id}/reject/item/${donorHire.id}/${item.item_id}`, o)
    },
    donor_hire (recipientHire, donorHire, o) {
      return requests.post(`warehouse/transfers/approval/${recipientHire.id}/reject/hire/${donorHire.id}`, o)
    },
    donor_project (recipientHire, donorProject, o) {
      return requests.post(`warehouse/transfers/approval/${recipientHire.id}/reject/project/${donorProject.id}`, o)
    },
    unreject_transfer (recipientHire, tfr) {
      return requests.delete(`warehouse/transfers/approval/${recipientHire.id}/reject/transfer/${tfr.id}`)
    }
  }
}
