import requests from '../../requests'

export default {
  list (po) {
    return requests.get(`finance/orders/${po.id}/lines`)
  },
  get (po, id) {
    return requests.get(`finance/orders/${po.id}/lines/${id}`)
  },
  add (po, o) {
    return requests.post(`finance/orders/${po.id}/lines`, o)
  },
  update (po, o) {
    return requests.put(`finance/orders/${po.id}/lines/${o.id}`, o)
  },
  remove (po, o) {
    return requests.delete(`finance/orders/${po.id}/lines/${o.id}`)
  },
  moveTo (po, from, to) {
    return requests.post(`finance/orders/${po.id}/lines/${from.id}/move-to?id=${to.id}`)
  }
}
