import requests from '../../requests'

export default {
  permissions (id) {
    return requests.options(`finance/orders/${id}/allocation`)
  },
  list (id) {
    return requests.get(`finance/orders/${id}/allocation`)
  },
  clear (id) {
    return requests.delete(`finance/orders/${id}/allocation`)
  },
  add (oid, pid, obj) {
    return requests.post(`finance/orders/${oid}/allocation/${pid}`, obj)
  },
  update (oid, pid, obj) {
    return requests.put(`finance/orders/${oid}/allocation/${pid}`, obj)
  },
  remove (oid, pid) {
    return requests.delete(`finance/orders/${oid}/allocation/${pid}`)
  }
}
