export default [
  {
    path: "/warehouse2",
    component: () => import("../../views/app/warehouse2/index.vue"),
    redirect: { name: "warehouse2-assets" },
    children: [
      {
        path: "assets",
        name: "warehouse2-assets",
        component: () => import("../../views/app/warehouse2/assets/index.vue")
      }
    ]
  },
  {
    path: "/warehouse2/assets/:assetId",
    component: () => import("../../views/app/warehouse2/assets/editor/index.vue"),
    name: "warehouse2-asset-editor"
  }
];
