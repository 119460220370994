import requests from '../../requests';
import stock from './stock';
import instances from './instances';

export default {
  stock,
  instances,
  get_orphaned_reservations() {
    return requests.get('assets/orphaned-reservations');
  },
  delete_orphaned_reservation(r) {
    return requests.delete(`assets/orphaned-reservations/${r.id}`);
  },
  get_cloned_reservations() {
    return requests.get('assets/cloned-reservations');
  },
  list_reservations(asset) {
    return requests.get(`assets/${asset.id}/reservations`);
  },
  create_trivial_item(asset) {
    return requests.post(`assets/${asset.id}/items`);
  },
  list_items(asset) {
    return requests.get(`assets/${asset.id}/items`);
  },
  list(withStockQuantity = true) {
    return requests.get('assets?withStockQuantity=' + withStockQuantity);
  },
  short_list() {
    return requests.get('assets/short');
  },
  get(id) {
    return requests.get(`assets/${id}`);
  },
  create(obj) {
    return requests.post('assets', obj);
  },
  update(obj) {
    return requests.put(`assets/${obj.id}`, obj);
  },
  delete(obj) {
    return requests.delete(`assets/${obj.id}`);
  },
  reservations: {
    release(asset, rsv) {
      return requests.delete(`assets/${asset.id}/reservations/${rsv.id}`);
    }
  },
  prices: {
    list(asset) {
      return requests.get(`assets/${asset.id}/prices`);
    },
    policies: {
      list(asset, policy) {
        return requests.get(`assets/${asset.id}/prices/${policy.id}`);
      },
      prices: {
        get(asset, policy, period) {
          return requests.get(`assets/${asset.id}/prices/${policy.id}/${period}`);
        },
        set(asset, policy, period, amount) {
          return requests.put(`assets/${asset.id}/prices/${policy.id}/${period}`, { amount });
        },
        unset(asset, policy, period) {
          return requests.delete(`assets/${asset.id}/prices/${policy.id}/${period}`);
        }
      }
    }
  },
  /*problems: {
    list() {
      return requests.get('assets/problems');
    },
    details(asset) {
      return requests.get(`assets/problems/${asset.id}`);
    },
    update_adjustment(asset, job, kind, o) {
      return requests.put(`assets/problems/${asset.id}/${job.id}/${kind}`, o);
    },
    remove_adjustment(asset, job, kind) {
      return requests.delete(`assets/problems/${asset.id}/${job.id}/${kind}`);
    },
    apply(info) {
      return requests.post(`assets/problems/${info.asset.id}`, info);
    },
  }*/
  problems: {
    list(asset) {
      return requests.get(`assets/${asset.id}/problems`);
    }
  }
};
