import requests from './ArcAPI/requests'

export default {
  get (path) {
    return requests.get(path)
  },
  post (path, obj) {
    return requests.post(path, obj)
  },
  put (path, obj) {
    return requests.put(path, obj)
  },
  delete (path, obj) {
    return requests.delete(path, obj)
  },
  patch (path, obj) {
    return requests.patch(path, obj)
  },
  options (path) {
    return requests.options(path)
  },
  async tur (path) {
    const result = await requests.post(`${path}$tur`)
    window.open(`/api/v1/tur/${result.token}/${result.filename}`)
  },
  errorCode (errorResponse) {
    if (errorResponse && errorResponse.response && errorResponse.response.data && errorResponse.response.data.code) {
      return errorResponse.response.data.code
    } else {
      return null
    }
  }
}
