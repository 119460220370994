<template>
  <searchable-object-selector
      ref="selector"
      :objects="sortedProjects"
      :disabled="disabled"
      :placeholder="placeholder"
      :formatter="formatter"
      @input="input"
      :value="value" />
</template>

<script>
export default {
  name: 'project-selector',
  props: {
    value: Object,
    allowNull: {
      Boolean,
      default () {
        return false
      }
    },
    disabled: {
      Boolean,
      default () {
        return false
      }
    },
    size: String,
    placeholder: String
  },
  data () {
    return {
      projects: []
    }
  },
  computed: {
    filteredProjects () {
      return this.projects.filter(p => p.status !== 'Cancelled')
    },
    sortedProjects () {
      return this.projects.slice().sort((a, b) => a.ref.localeCompare(b.ref))
    }
  },
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    selectById (id) {
      this.$refs.selector.selectById(id)
    },
    formatter (p) {
      return `${p.ref}: ${p.title}`
    }
  },
  async mounted () {
    this.projects = await ArcAPI.projects.quick_list()
  }
}
</script>
