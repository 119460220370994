import requests from '../requests'

export default {
  list (policy) {
    return requests.get(`finance/asset-prices/${policy.id}`)
  },
  get (policy, id) {
    return requests.get(`finance/asset-prices/${policy.id}/${id}`)
  },
  update (policy, obj) {
    return requests.put(`finance/asset-prices/${policy.id}/${obj.id}`, obj)
  },
  delete (policy, obj) {
    return requests.delete(`finance/asset-prices/${policy.id}/${obj.id}`)
  }
}
