import Vue from 'vue';
import Router from 'vue-router';
import Title from '../util/title';

import projects from './projects';
import hires from './hires';
import config from './config';
import warehouse from './warehouse';
import warehouse2 from './warehouse2';
import finance from './finance';
import reports from './reports';
import companies from './companies';
import logistics from './logistics';
import system from './system';
import compliance from './compliance';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: '/dashboard'
    },
    {
      path: '/404',
      component: () => import('../views/app/NotFound.vue'),
      meta: { title: 'Not Found' }
    },
    {
      path: '/not-authorised',
      component: () => import('../views/app/NotAuthorised.vue'),
      meta: { title: 'Not Authorised' }
    },
    {
      path: '/quick-create',
      name: 'QuickCreate',
      component: () => import('../views/app/QuickCreate.vue'),
      meta: { title: 'Quick Create' }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('../views/app/dashboard/index.vue'),
      redirect: { name: 'DashboardOverview' },
      children: [
        {
          path: '/dashboard/personal',
          component: () => import('../views/app/dashboard/Personal.vue'),
          name: 'PersonalDashboard',
          meta: { title: 'Dashboard' }
        },
        {
          path: '/dashboard/overview',
          component: () => import('../views/app/dashboard/overview/index.vue'),
          name: 'DashboardOverview',
          meta: { title: 'Overview' }
        },
        {
          path: '/dashboard/projects',
          component: () => import('../views/app/dashboard/projects/index.vue'),
          name: 'DashboardProjects',
          meta: { title: 'Projects' }
        },
        {
          path: '/dashboard/schedule',
          component: () => import('../views/app/dashboard/schedule/index.vue'),
          name: 'DashboardSchedule',
          meta: { title: 'Schedule' }
        },
        {
          path: '/dashboard/finance',
          component: () => import('../views/app/dashboard/finance/index.vue'),
          name: 'DashboardFinance',
          meta: { title: 'Finance' }
        }
      ]
    },
    {
      path: '/dashboard/client/:id',
      component: () => import('../views/app/dashboard/Client.vue'),
      meta: { title: 'Client Dashboard' }
    },
    {
      path: '/account',
      component: () => import('../views/app/account/index.vue'),
      meta: { title: 'Account Information' }
    },
    {
      path: '/test',
      component: () => import('../views/app/Test.vue')
    },
    {
      path: '/people/:id',
      component: () => import('../views/app/people/index.vue'),
      meta: { title: 'Person Editor' }
    },
    {
      path: '/director-reports',
      component: () => import('../views/app/director-reports/index.vue'),
      meta: { title: 'Director Reports' }
    },
    {
      path: '/director-reports/user-activity',
      component: () => import('../views/app/director-reports/UserActivity.vue'),
      meta: { title: 'User Activity' }
    },
    {
      path: '/director-reports/usage-log',
      component: () => import('../views/app/director-reports/UsageLog.vue'),
      meta: { title: 'Usage Log' }
    },
    {
      path: '/venues/:id',
      component: () => import('../views/app/venue-editor/index.vue'),
      meta: { title: 'Venue Editor' }
    },
    {
      path: '/scheduler',
      component: () => import('../views/app/scheduler/index.vue'),
      redirect: { name: 'SchedulerGlobal' },
      children: [
        {
          path: 'global',
          name: 'SchedulerGlobal',
          component: () => import('../views/app/scheduler/global/index.vue')
        },
        {
          path: 'personal',
          name: 'SchedulerPersonal',
          component: () => import('../views/app/scheduler/personal/index.vue')
        }
      ]
    },
    {
      path: '/workshop',
      component: () => import('../views/app/workshop/index.vue'),
      redirect: { name: 'WorkshopHires' },
      children: [
        {
          path: 'schedule',
          name: 'WorkshopSchedule',
          component: () => import('../views/app/workshop/schedule/index.vue')
        },
        {
          path: 'jobs',
          name: 'WorkshopJobs',
          component: () => import('../views/app/workshop/jobs/index.vue')
        },
        {
          path: 'hires',
          name: 'WorkshopHires',
          component: () => import('../views/app/workshop/hires/index.vue')
        }
      ]
    },
    {
      path: '/contracts/:contractId',
      component: () => import('../views/app/contract-editor/index.vue'),
      name: 'contract-editor'
    },
    ...reports,
    ...projects,
    ...hires,
    ...config,
    ...warehouse,
    ...warehouse2,
    ...finance,
    ...companies,
    ...logistics,
    ...system,
    ...compliance,
    {
      path: '/logistics/transport/schedules/:schedId',
      component: () => import('../views/app/logistics/transport/schedules/editor/index.vue'),
      meta: { title: 'Transport Schedule' }
    },
    {
      path: '/warehouse/asset-problems/editor/:problemId',
      component: () => import('../views/app/warehouse/asset-problems/editor/index.vue'),
      name: 'ProblemEditor'
    },
    {
      path: '/hires/:hireId/editor',
      name: 'HireEditorEditor',
      component: () => import('../views/app/hire-editor/editor/index.vue'),
      meta: { title: 'Hire Editor' }
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/login/index.vue'),
      meta: { authPolicy: 'anon', layout: 'LoginLayout' }
    },
    {
      path: '*',
      component: () => import('../views/app/NotFound.vue')
    }
  ]
});

router.afterEach((to, from) => {
  Title.reset();
});

export default router;
